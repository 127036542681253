import {
  Accordion,
  AccordionDetails,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../category.css";
import { ArrowBack, CloseOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { blue, pink } from "@mui/material/colors";
import ReactQuill from "react-quill";
import CategoryListItems from "../common/CategoryListItems";
import EditCategoryItem from "../common/EditCategoryItem";
import { update_Faq_Category, uploadImage } from "../../auth/service";
import { useCallback } from "react";

const EditCategory = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [categorylist, setCategorylist] = useState([]);
  const [qnalist, setQnalist] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [altTag, setAltTag] = useState("");
  const [checkboxError, setCheckboxerror] = useState("");
  const [error, setError] = useState({});
  const [category, setCategory] = useState("");

  const [editModal, setEditModal] = useState(false);
  const [editId, setEditid] = useState(null);
  const navigate = useNavigate();
  const paramsId = useParams();
  const handleNavigate = async () => {
    navigate("/dashboard/faq/category-list");
  };
  const handleModal = (value) => {
    setIsModal(value);
  };
  const fetchData = async () => {
    try {
      const response = await fetch(`
     
       https://api.obenev.com/categories
     `);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();

      setCategorylist(jsonData?.data);

      let singleCategory = jsonData?.data?.filter(
        (item) => item?._id == paramsId?.id
      );

      setCategory(singleCategory[0]?.name);
      setIsActive(singleCategory[0]?.active);
      setQnalist(singleCategory[0]?.faqs);

      // setIsUploadingImg(true);
      // setSlug(
      //   singleCategory[0]?.hasOwnProperty("meta")
      //     ? singleCategory[0].meta?.slug || ""
      //     : ""
      // );
      setImageUrl(
        singleCategory[0]?.hasOwnProperty("meta")
          ? singleCategory[0]?.meta?.image_src
          : ""
      );
      setAltTag(
        singleCategory[0]?.hasOwnProperty("meta")
          ? singleCategory[0].meta?.image_alt_tag || ""
          : ""
      );
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    setCategory(e.target.value);
  };

  const handleDeleteItem = (index) => {
    setQnalist(qnalist.filter((qna) => qna?.id !== index));
    toast.success("Deleted successfully");
  };

  const validationerror = {};
  const validateFields = () => {
    if (!category) {
      validationerror.category = "Please enter your category name";
    }

    // if (!isActive) {
    //   validationerror.checkboxError = "Please check the checkbox";
    // }
    return validationerror;
  };
  const categoryValidation = () => {
    if (!question.trim()) {
      validationerror.questiion = "Please enter your question";
    }
    if (!answer.trim()) {
      validationerror.answer = "Please enter your answer";
    }

    return validationerror;
  };

  const handleSlug = (e) => {
    setSlug(e.target.value);
  };

  const addQna = () => {
    const validation = categoryValidation();
    setError(validation);
    if (question !== "" && answer !== "" && validation) {
      const newQna = {
        id: qnalist.length + 1, // Assuming each item has a unique id
        meta_title: metaTitle,
        meta_description: metaDescription,
        slug: slug,
        questiion: question,
        answer: answer,
      };

      setQnalist([...qnalist, newQna]);
      handleModal(false);
      setQuestion("");
      setAnswer("");
    }
  };

  const updateCategory = async () => {
    let categoryValidation = "category";
    const validation = validateFields(categoryValidation);
    setError(validation);

    if (category !== "") {
      try {
        const resp = await update_Faq_Category(
          {
            name: category,
            active: isActive,
            faqs: qnalist,
            meta: {
              image_src: imageUrl,
              image_alt_tag: altTag,
            },
          },
          paramsId?.id
        );

        // Check if the update was successful
        if (resp && !resp?.error) {
          // Display success message in toaster
          toast(resp?.message);
          setTimeout(() => {
            navigate("/dashboard/faq/category-list");
          }, 1500);
        } else {
          // Display error message in toaster if there was an issue with the update
          toast("Failed to update category. Please try again.", "error");
        }
      } catch (error) {
        // Display error message in toaster if there was an error with the request
        toast("An error occurred while updating category", "error");
      }
    }
  };

  const UpdateQna = (updateValue) => {
    const newQna = {
      id: updateValue?.id, // Assuming each item has a unique id
      meta_title: updateValue?.meta_title,
      meta_description: updateValue?.meta_description,
      slug: updateValue?.slug,
      questiion: updateValue?.questiion,
      answer: updateValue?.answer,
    };
    const updatedData = qnalist.filter((item) => item?.id !== editId);

    setQnalist(
      [...updatedData, newQna].sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    );
  };
  const hadnleEditIsModal = (boolean) => {
    setEditModal(boolean);
  };
  const handleChildIdChange = (id) => {
    setEditid(id);
  };

  const handleCheckboxChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleImageAltTag = (e) => {
    setAltTag(e.target.value);
  };
  let formData = new FormData();
  const getSelectedFile = useCallback(
    async (e) => {
      try {
        setIsUploadingImg(true);
        const file = e.target.files[0];
        formData.append("image", file);

        const res = await uploadImage(formData);
        if (res.status === "success") {
          const image = res?.data?.Location;
          setImageUrl(image);
        } else {
        }
      } catch (error) {
        // setError("Something went wrong");
      } finally {
        setIsUploadingImg(false);
      }
    },
    [formData]
  );

  return (
    <div className="category-edit">
      <div className="category-header-container">
        <span className="blog-header">Edit Category</span>

        <div className="btn-flex">
          <div className="btn-container ">
            <Button
              variant="contained"
              className="bordered-button"
              size="medium"
              onClick={updateCategory}
            >
              SAVE CATEGORY
            </Button>
          </div>
          <div className="btn-container ">
            <Button
              variant="outlined"
              className="bordered-button"
              startIcon={<ArrowBack />}
              size="medium"
              onClick={handleNavigate}
            >
              BACK
            </Button>
          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <label htmlFor="inputField" className="label">
            Category Name
          </label>
          <div>
            <input
              value={category}
              type="text"
              id="inputField"
              className="input"
              onChange={handleChange}
            />
            {error?.category && (
              <p className="faqs-error" style={{ display: "block" }}>
                {error?.category}
              </p>
            )}
          </div>
        </div>

        <div className="container">
          <label htmlFor="inputField" className="label">
            Status
          </label>
          {/* <Checkbox
            defaultChecked
            label="Active"
            size="small"
            sx={{
              color: blue[800],
              "&.Mui-checked": {
                color: blue[600],
              },
            }}
          /> */}

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "blue" }}
                checked={isActive}
                onChange={handleCheckboxChange}
              />
            }
            label="Active"
          />
          {error?.checkboxError && (
            <p className="faqs-error" style={{ display: "block" }}>
              {error?.checkboxError}
            </p>
          )}
        </div>

        <div className="container">
          <label htmlFor="inputField" className="label">
            Upload Image
          </label>
          {imageUrl == null || imageUrl == false ? (
            <>
              {isUploadingImg ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className="bordered-button"
                  style={{ width: 200, fontSize: 14, color: "#fff" }}
                  component="label"
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getSelectedFile}
                    hidden
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              <div style={{ maxWidth: 400 }} className="mUpload-item">
                <img
                  src={imageUrl}
                  width="80px"
                  style={{ filter: "grayscale(1) invert(1)" }}
                  alt="img"
                />
                <input
                  className="fw"
                  type="text"
                  placeholder="Enter alt tag"
                  value={altTag}
                  onChange={handleImageAltTag}
                  name="alt_tag"
                />
                <DeleteIcon onClick={() => setImageUrl(null)} />
              </div>
            </>
          )}
        </div>

        <div className="container">
          <label htmlFor="inputField" className="label">
            Faqs
          </label>
          <Button
            onClick={() => handleModal(true)}
            variant="outlined"
            size="small"
            // startIcon={<Add />}
          >
            ADD QNA
          </Button>
        </div>

        <ToastContainer />
        {qnalist?.length > 0
          ? qnalist?.map((qnalist) => {
              return (
                <React.Fragment key={qnalist?.id}>
                  <CategoryListItems
                    key={qnalist?.id}
                    qnalist={qnalist}
                    onIdChange={handleChildIdChange}
                    onDelete={handleDeleteItem}
                    editModal={hadnleEditIsModal}
                  />
                </React.Fragment>
              );
            })
          : ""}

        {/* <div className="container">
          <div className="label"></div>
          <div>
            <div className="question">
              <h4 className="quest-header">Question</h4>
              <Typography className="font600 fs_14">
                What businesses use GO Bermondsey Coworking Space?
              </Typography>
            </div>
            <div className="answer">
              <h4 className="quest-header">Answer</h4>
              <Typography className="font600 fs_14">
                GO Bermondsey coworking space offers top-notch shared amenities
                to all kinds of businesses - small, medium, or large. From
                startups and freelancers to large businesses with remote
                workers, some of the fastest-growing brands are operating from
                GO Bermondsey.
              </Typography>
              <DeleteIcon style={{ cursor: "pointer" }} />
              <EditIcon style={{ cursor: "pointer" }} />
            </div>
          </div>
        </div> */}
      </div>
      {isModal ? (
        <Accordion>
          <AccordionDetails>
            <div className="Quil-maodal-header">
              <h3>Add Faq</h3>
              <CloseOutlined
                style={{ cursor: "pointer" }}
                onClick={() => handleModal(false)}
              />
            </div>

            <div className="Quil-one">
              <div className="form_comp">
                <label className="title" htmlFor="title">
                  Meta Title
                </label>
                <input
                  className="textField"
                  type="text"
                  id="title"
                  placeholder="Enter Title"
                  name="metaTitle"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  bounds={`[data-text-editor="name"]`}
                />
              </div>

              <div className="form_comp">
                <label className="title" htmlFor="title">
                  Meta Description
                </label>
                <input
                  className="textField"
                  type="text"
                  id="title"
                  placeholder="Enter Title"
                  name="metaDescription"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  bounds={`[data-text-editor="name"]`}
                />
              </div>

              <div className="form_comp">
                <label className="title" htmlFor="title">
                  Slug
                </label>
                <input
                  className="textField"
                  type="text"
                  id="title"
                  placeholder="Enter Title"
                  name="slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  bounds={`[data-text-editor="name"]`}
                />
              </div>
              <label className="title" htmlFor="title">
                Question
              </label>

              <div className="form_comp">
                <ReactQuill
                  className="textField"
                  type="text"
                  id="title"
                  placeholder="Enter Title"
                  name="title"
                  value={question}
                  onChange={(value) => setQuestion(value)}
                  bounds={`[data-text-editor="name"]`}
                />
              </div>
              {error?.questiion && (
                <p className="faqs-error" style={{ display: "block" }}>
                  {error?.questiion}
                </p>
              )}
            </div>

            <div className="Quil-two">
              <label className="title" htmlFor="title">
                Answer
              </label>

              <div className="form_comp">
                <ReactQuill
                  className="textField"
                  type="text"
                  id="title"
                  placeholder="Enter Title"
                  name="title"
                  value={answer}
                  onChange={(value) => setAnswer(value)}
                  bounds={`[data-text-editor="name"]`}
                />
              </div>
              {error?.questiion && (
                <p className="faqs-error" style={{ display: "block" }}>
                  {error?.questiion}
                </p>
              )}
            </div>
            <div className="btn-group">
              <Button
                className="quil-close-btn"
                size="medium"
                variant="outlined"
                onClick={() => handleModal(false)}
              >
                Close
              </Button>
              <Button size="medium" variant="contained" onClick={addQna}>
                Add
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}

      {editModal ? (
        <EditCategoryItem
          id={editId}
          qna={qnalist}
          pullUpdateQna={UpdateQna}
          editModal={hadnleEditIsModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default EditCategory;
