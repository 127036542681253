export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const STATES = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const getArrOrder = (arr = []) => {
  let order = arr?.map((a) => a?._id || a?.id);
  return order;
};

export const arrToObjMap = (arr = []) => {
  const obj = arr.reduce((mObj, item) => {
    var key = item?._id || item?.id;
    mObj[key] = item;
    return mObj;
  }, {});

  return Object.assign({}, obj);
};
