import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { dummy, colourOptions, options } from "./Index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import JoditEditior from "../../../shared/Editor/JoditEditior";
import {Button} from "@mui/material";
import axios from "axios";
import {BASE_URL} from "../../../shared/constants";



const JobCreateModel = (props) => {
    const [data, setData] = useState({}); // Holds job data
    const { id } = useParams();
    const navigate = useNavigate();

    // // Fetch data based on ID
    // useEffect(() => {
    //     if (id) {
    //         const userData = dummy.find((user) => user.id === parseInt(id));
    //         setData(userData);
    //     }
    // }, [id]);
    //
    // // Handler for input and select changes

    // {
    //     id: 1,
    //         date: "2024-09-30",
    //     name: "Jane Smith",
    //     email: "janesmith@example.com",
    //     mobile: "+0987654321",
    //     jobTitle: "Data Scientist",
    //     resume: "Link to Jane's resume",
    //     city: "New Delhi",
    //     department: "IT Services",
    //     noticePeriod: "30",
    //     coverLetter:
    //     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...",
    // }

    const submitForm = () => {
        const payload = {
            category: data?.category?.value,
            jobTitle: data?.jobTitle?.value,
            jobType: data?.jobType?.value,
            jobDescription: data?.jobDescription,
            experience: data?.experience,
            qualification: data?.qualification,
            preferredLocation: data?.preferredLocation?.value,
            tags: data?.tags,
        }
        axios.post(`${BASE_URL}/jobs`, {data: payload},
        //
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // }
        ).then(res=>{
            console.log(res.data.data);
            props.onClose();
        }).catch(err=>{
            alert("Error creating Job Create Job");
        });

    };
    const handleChange = useCallback(
        (name) => (e) => {
            let value = e?.target?.value ?? e?.target?.checked ?? e;
            setData((prevState) => ({ ...prevState, [name]: value }));
        },
        [setData]
    );
    //
    // if (!data) {
    //     return <div>Loading...</div>; // Shows a loading state when data is not available yet
    // }

    return (
        <div className="blogs-container">
            <div className="blog-container" style={{ overflow: "initial" }}>
                <div className="blog-header-container">
          <span className="blog-header">
            <ArrowBackIosIcon
                className="back_button"
                onClick={props.onClose}
            />
            Job Detail
          </span>
                </div>

                <div className="form_o_cb">
                    <div className="form_row">
                        {/* Job Title */}
                        <div className="form_col_6 form_container_box">
                            <label>Job Details</label>
                            <Select
                                options={options}
                                value={options.find((opt) => opt.value === data.jobTitle)} // Binding selected value
                                onChange={handleChange("jobTitle")} // Updates state when selecting job type
                            />
                        </div>

                        {/* Job Type */}
                        <div className="form_col_6 form_container_box">
                            <label>Job Type</label>
                            <Select
                                options={options}
                                value={options.find((opt) => opt.value === data.jobType)} // Binding selected value
                                onChange={handleChange("jobType")} // Updates state when selecting job type
                            />
                        </div>

                        {/* Category */}
                        <div className="form_col_6 form_container_box">
                            <label>Category</label>
                            <Select
                                options={options}
                                value={options.find((opt) => opt.value === data.category)} // Binding selected value
                                onChange={handleChange("category")} // Updates state when selecting job type
                            />
                        </div>

                        {/* Tags */}
                        <div className="form_col_6 form_container_box">
                            <label>Tags</label>
                            <Select
                                defaultValue={colourOptions.filter(
                                    (option) => ["tag1", "tag2"].includes(option.value) // Replace with dynamic tag list
                                )}
                                isMulti
                                name="colors"
                                options={colourOptions}
                                classNamePrefix="select"
                                onChange={handleChange("tags")} // Handling tags selection
                            />
                        </div>

                        {/* Qualification */}
                        <div className="form_col_6 form_container_box">
                            <label>Qualification</label>
                            <input
                                type="text"
                                className="set_height"
                                placeholder="Qualification"
                                autoComplete="off"
                                value={data.qualification || ""}
                                onChange={handleChange("qualification")} // Binding state
                            />
                        </div>

                        {/* Job for preferred Location */}
                        <div className="form_col_6 form_container_box">
                            <label>Job for Preferred Location</label>
                            <div className="set_height">
                                <Select
                                    options={options}
                                    value={options.find(
                                        (opt) => opt.value === data.preferredLocation
                                    )}
                                    onChange={handleChange("preferredLocation")} // Binding state
                                />
                            </div>
                        </div>

                        {/* Experience */}
                        <div className="form_col_6 form_container_box">
                            <label>Experience</label>
                            <input
                                type="text"
                                className="set_height"
                                placeholder="Experience in months or years"
                                autoComplete="off"
                                value={data.experience || ""}
                                onChange={handleChange("experience")} // Binding state
                            />
                        </div>
                    </div>

                    {/* Job Description */}
                    <div className="form_comp">
                        <label htmlFor="title">Job Description</label>
                        <JoditEditior
                            content={data.jobDescription || ""}
                            onChange={handleChange("jobDescription")}
                            setContent={""}
                            config={{
                                minHeight: 600,
                            }}
                            // Handling editor changes
                        />
                    </div>
                    <Button
                        variant="contained"
                        className="bordered-button"
                        onClick={submitForm}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        className="bordered-button"
                        onClick={props.onClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default JobCreateModel;
