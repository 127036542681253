import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import storeReducer from "../features/store-locations/duck/StoreReducer";
import CityReducer from "../features/store-locations/duck/CityReducer";
import rootSaga from "./rootSaga";

// import { StatsReducer } from "../features/statistics";

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    console.error("CreateSagaMiddleware", err);
    alert("There is some problem. Please check console");
  },
});

const rootReducer = combineReducers({
  store: storeReducer,
  city: CityReducer,
  // stat: StatsReducer,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    });
    defaultMiddleware.push(sagaMiddleware);
    return defaultMiddleware;
  },
  // devTools: true,
});

sagaMiddleware.run(rootSaga);
export default store;
