import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
} from "@mui/material";
import {
  create_Testimonial,
  getSingle_Testimonial,
  update_Testimonial,
  uploadImage,
} from "../../auth/service";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const AddTestimonmonialModal = ({ open, handleClose, testimonialId }) => {
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [TestimonialDetails, setTestimonialDetails] = useState({
    review: "",
    testimonialUrl: "",
    imgAlt: "",
    src: "",
    isActive: "",
  });

  const [error, setError] = useState({});
  const prevProp = useRef();

  const fetchData = async () => {
    try {
      const response = await getSingle_Testimonial(testimonialId);

      setTestimonialDetails({
        isActive: response?.data?.active ?? "",
        review: response?.data?.description ?? "",
        testimonialUrl: JSON.parse(response?.data?.meta?.campaign_url) ?? "",
        imgAlt: JSON.parse(response?.data?.meta?.img_alt) ?? "",
        src: response?.data?.src ?? "",
      });
    } catch (error) {
      // setError(error);
    }
  };
  useEffect(() => {
    setTestimonialDetails({
      review: "",
      testimonialUrl: "",
      imgAlt: "",
      src: "",
      isActive: "",
    });
    if (testimonialId) {
      fetchData();
      setError({});
    }

    return () => {
      prevProp.current = {
        open,
      };
    };
  }, [testimonialId, open]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setTestimonialDetails((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );
  const handleCheckboxChange = (event) => {
    let value = event.target.checked;
    setTestimonialDetails((preState) => ({ ...preState, isActive: value }));
  };
  const hasError = useCallback(() => {
    let { review, testimonialUrl, src } = TestimonialDetails;
    review = review?.trim?.();
    testimonialUrl = testimonialUrl?.trim?.();
    src = src?.trim?.();

    const error = {};
    if (!review) {
      error.review = "Please enter review";
    }
    if (!testimonialUrl) {
      error.testimonialUrl = "Please enter testimonial url";
    }

    if (!src) {
      error.fileList = "Upload icon";
    }

    setError(error);
    return Object.keys(error).length;
  }, [TestimonialDetails]);

  const getSelectedFile = useCallback(async (e) => {
    let formData = new FormData();
    try {
      setIsUploadingImg(true);
      const file = e.target.files[0];
      formData.append("image", file);

      const res = await uploadImage(formData);
      if (res.status === "success") {
        const image = res?.data?.Location;
        setTestimonialDetails((prevData) => ({ ...prevData, src: image }));
      }
      setError({});
    } catch (error) {
      setError("Something went wrong");
    } finally {
      setIsUploadingImg(false);
    }
  }, []);

  const createTestimonial = async () => {
    if (!hasError()) {
      if (testimonialId) {
        try {
          const resp = await update_Testimonial(
            {
              status: true,
              active: TestimonialDetails.isActive,
              description: TestimonialDetails.review,
              src: TestimonialDetails.src,

              meta: {
                img_alt: JSON.stringify(TestimonialDetails.imgAlt),
                campaign_url: JSON.stringify(TestimonialDetails.testimonialUrl),
              },
            },
            testimonialId
          );
        } catch (error) {
          // Handle error from API call
          console.warn("Failed to update category", "error");
        }
      } else {
        try {
          const resp = await create_Testimonial({
            status: true,
            active: true,
            description: TestimonialDetails.review,
            src: TestimonialDetails.src,

            meta: {
              img_alt: JSON.stringify(TestimonialDetails.imgAlt),
              campaign_url: JSON.stringify(TestimonialDetails.testimonialUrl),
            },
          });

          // Check if response indicates success
          // if (!resp?.error) {
          //   toast.success(resp?.message);
          //   setTimeout(() => {
          //     navigate("/dashboard/faq/category-list");
          //   }, 1500);
          // } else {
          //   // If response indicates an error, show the error message
          //   toast.success(resp.message || "An error occurred", "error");
          // }
        } catch (error) {
          // Handle error from API call
          console.warn("Failed to create category", "error");
        }
      }
      handleClose();
    }
  };

  console.log(open);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>
          {testimonialId ? "Update Campaign Review" : "Add Campaign Review"}
        </h2>
        <div className="form_comp">
          <label htmlFor="title">
            Campaign Review
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <textarea
            rows="4"
            placeholder="Campaign Review"
            value={TestimonialDetails.review}
            onChange={handleChange("review")}
          />
          <span className="error mt5">{error.review}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Campaign Url
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            type="text"
            placeholder="Campaign url"
            value={TestimonialDetails.testimonialUrl}
            onChange={handleChange("testimonialUrl")}
            autoComplete="off"
          />
          <span className="error mt5">{error.testimonialUrl}</span>
        </div>

        <div className="form_comp">
          <label htmlFor="title">
            Icon <sup style={{ color: "red" }}>*</sup>
          </label>
          {!TestimonialDetails?.src ? (
            <>
              {isUploadingImg ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className="bordered-button"
                  style={{ width: 200, fontSize: 14, color: "#fff" }}
                  component="label"
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getSelectedFile}
                    hidden
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              <div style={{ maxWidth: 400 }} className="mUpload-item">
                <img
                  src={TestimonialDetails?.src}
                  width="80px"
                  height="80px"
                  alt="img"
                />
                <input
                  className="fw"
                  type="text"
                  placeholder="Enter alt tag"
                  value={TestimonialDetails?.imgAlt}
                  onChange={handleChange("imgAlt")}
                />
                <DeleteIcon
                  onClick={() =>
                    setTestimonialDetails((preState) => ({
                      ...preState,
                      src: "",
                    }))
                  }
                />
              </div>
            </>
          )}
          <span className="error mt5">{error.fileList}</span>
        </div>
        {testimonialId && TestimonialDetails && (
          <div className="form_comp_checkbox">
            <label htmlFor="inputField" className="label">
              Status
              {/* <sup style={{ color: "red" }}>*</sup> */}
            </label>

            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "blue" }}
                  checked={TestimonialDetails.isActive}
                  onChange={handleCheckboxChange}
                />
              }
              label="Active"
            />
          </div>
        )}
        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 15,
          }}
        >
          <Button variant="contained" onClick={createTestimonial}>
            {testimonialId ? "Update" : "Add"}
          </Button>
          <Button
            variant="text"
            className="bordered-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddTestimonmonialModal;
