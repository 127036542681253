import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

const TestimonialCard = ({ reviewList, pullHandleData, active }) => {
  const handleModal = (id) => {
    pullHandleData(true, id);
  };
  const { src, description } = reviewList;
  return (
    <Card style={{ height: "100%", opacity: `${!active ? "0.5" : ""}` }}>
      <div className="box-wrapper">
        <CardHeader
          style={{ textTransform: "capitalize" }}
          avatar={<Avatar src={src} />}
          action={
            <>
              <Button onClick={() => handleModal(reviewList?._id)}>
                <EditIcon />
              </Button>
            </>
          }
        />
        <hr />
        <CardContent>
          <Typography className="card-que-body">
            {description}
            {/* {categorylist?.faqs[0]?.questiion?.replace(/(<([^>]+)>)/gi, "")} */}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};

export default TestimonialCard;
