import React, { useEffect, useState } from "react";
import Select from "react-select";

const MultipleSelect = ({ options, onChange, existingTags }) => {
  const [selectedOption, setSelectedOption] = useState([]);
  useEffect(() => {
    setSelectedOption(
      existingTags
        ? options.filter((option) => existingTags.includes(option.value))
        : []
    );
  }, [existingTags, options]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "5px",
    }),
    multiValue: (provided) => ({
      ...provided,
      background: "#333",
      color: "#ffffff",
      padding: "4px 8px",
      borderRadius: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#ffffff",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#ffffff",
      ":hover": {
        background: "#333",
      },
    }),

    placeholder: (provided) => ({
      ...provided,
      fontSize: "18px",
      color: "rgba(128, 128, 128, 0.6)",
    }),

    menu: (provided) => ({
      ...provided,
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
      fontSize: "16px",
      fontFamily: "Poppins",
      zIndex: 1000,
      background: "#fff",
    }),
  };

  return (
    <div className="">
      <Select
        style={{ outline: "none" }}
        value={selectedOption}
        onChange={(selectedOptions) => {
          setSelectedOption(selectedOptions);
          onChange(selectedOptions);
        }}
        options={options}
        isMulti={true}
        placeholder="Select Popular tags"
        styles={customStyles}
        isSearchable={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={true}
        backspaceRemovesValue={true}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "#ffffff",
            primary50: "#ffffff",
            primary: "#333",
          },
        })}
      />
    </div>
  );
};

export default MultipleSelect;
