import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const CategoryListItems = ({ qnalist, onDelete, onIdChange, editModal }) => {
  const handleDelete = (index) => {
    onDelete(index);
  };

  const handleSendEditId = (index) => {
    onIdChange(index);
    handleModal();
  };
  const handleModal = () => {
    editModal(true);
  };

  return (
    <div className="container">
      <div className="label"></div>
      <div>
        <div className="question">
          <h4 className="quest-header">Question</h4>
          <Typography
          // dangerouslySetInnerHTML={{ __html: qnalist?.questiion }}
          >
            {" "}
            {qnalist?.questiion.replace(/(<([^>]+)>)/gi, "")}
          </Typography>
        </div>
        <div className="answer">
          <h4 className="quest-header">Answer</h4>
          <Typography
          // dangerouslySetInnerHTML={{ __html: qnalist?.answer }}
          >
            {qnalist?.answer.replace(/(<([^>]+)>)/gi, "")}
          </Typography>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(qnalist?.id)}
          >
            {" "}
          </DeleteIcon>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleSendEditId(qnalist?.id)}
          />
        </div>
        <div
          style={{
            width: "100%",
            background: "gray",
            height: "1px",
            marginTop: "30px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default CategoryListItems;
