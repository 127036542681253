import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../shared/Loader";
import { create_Banner, edit_banner, uploadImage } from "../../auth/service";
import {
  Container,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Snackbar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../createForm.css";
import Add from "../assets/Add.svg";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Editor from "../../../shared/Editor/Editor";
import ReactQuill from "react-quill";

const CreateBanner = ({ action, bannerdetails }) => {
  const navigate = useNavigate();
  const [editorContent, setEditorContent] = useState(
    action === "edit" ? bannerdetails.content : ""
  );
  const [editEditorContent, setEditEditorContent] = useState();
  const [data, setData] = useState({
    title: "",
    src: "",
    alt_tag: "",
    category: "Banner",
    description: editorContent,
    meta: { title_url: "", mobile_src: "", mobile_alt_tag: "" },
  });
  console.log("data", data, "===", data?.meta?.mobile_src);
  const [loading, setLoading] = useState(false);
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [isUploadingImgMobile, setIsUploadingImgMobile] = useState(false);
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // let history = useHistory();
  let formData = new FormData();
  let formBannerData = new FormData();

  const getSelectedFile = useCallback(
    async (e) => {
      try {
        setIsUploadingImg(true);
        const file = e.target.files[0];
        formData.append("image", file);

        const res = await uploadImage(formData);
        if (res.status === "success") {
          const image = res?.data?.Location;
          setData((prevData) => ({ ...prevData, src: image }));
        } else {
        }
      } catch (error) {
        setError("Something went wrong");
      } finally {
        setIsUploadingImg(false);
      }
    },
    [formData]
  );

  const getSelectedFileMobile = useCallback(
    async (e) => {
      try {
        setIsUploadingImgMobile(true);
        const file = e.target.files[0];
        formData.append("image", file);

        const res = await uploadImage(formData);
        if (res.status === "success") {
          const image = res?.data?.Location;

          setData((prevData) => ({
            ...prevData,
            meta: {
              ...prevData.meta,
              mobile_src: image,
            },
          }));
        } else {
        }
      } catch (error) {
        setError("Something went wrong");
      } finally {
        setIsUploadingImgMobile(false);
      }
    },
    [formData]
  );

  const getformData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "alt_tag") {
      value = value.trim();
    }
    if (name === "title_url") {
      setData((prevData) => ({
        ...prevData,
        meta: {
          ...prevData.meta,
          title_url: value,
        },
      }));
    }
    if (name === "mobile_alt_tag") {
      setData((prevData) => ({
        ...prevData,
        meta: {
          ...prevData.meta,
          mobile_alt_tag: value,
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    setIsActive(e.target.checked);
  };
  const CreateNewblog = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      setErrorMessage("Please fill in all fields");
      return;
    }

    setErrorMessage("");
    let bugs = false;
    try {
      // for (let key in data) {
      // if (!bugs) {
      // if (data) {
      //   alert("please fill all fields");
      //   bugs = true;
      //   // break;
      // }
      let resp;
      if (action === "edit") {
        console.log("Edit Data 1", data);
        const res = await edit_banner({
          ...data,
          // title: bannerdetails.title,
          // title: bannerdetails.title,

          // category: bannerdetails.category,
          // src: bannerdetails.src,
          bannerId: bannerdetails?._id,
          active: isActive,
        });
        console.log("bannerId res", res);
        console.log("Edit Data 2", data);
        resp = res;
        console.log("Endd");
        console.log("Edit Data 3", data);
      } else {
        console.log("dataaaaa", data);
        const res = await create_Banner({
          ...data,
        });
        resp = res;
      }
      if (!resp.error) {
        toast.success(resp.message);
        window.location.reload();
      }
      // }
      // }
    } catch (error) {
      toast.error("something is wrong");
    }
  };

  useEffect(() => {
    if (action === "edit") {
      // console.log("Start",editorContent)
      setData({
        ...data,
        title: bannerdetails.title,
        description: bannerdetails.description,
        category: bannerdetails.category,
        alt_tag: bannerdetails.alt_tag,
        src: bannerdetails.src,
        meta: {
          title_url: bannerdetails.meta?.title_url || "",
          mobile_src: bannerdetails.meta?.mobile_src || "",
          mobile_alt_tag: bannerdetails.meta?.mobile_alt_tag || "",
        },
      });
      setIsActive(!!bannerdetails.active);
    }
  }, []);

  const category = [
    "Trending",
    "Performance",
    "Charging",
    "Battery",
    "Design",
    "Tech",
    "News",
  ];

  if (loading) {
    return <Loader />;
  }
  const handleChangeEditorData = (e) => {
    console.log("Handle Change");
    setData({ ...data, description: e.target.value });
  };

  const mandatoryFields = ["title", "description", "category"];
  const validateFields = () => {
    let isValid = true;
    for (const field of mandatoryFields) {
      if (!data[field]) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  return (
    <>
      <div className="create-form--container">
        <div className="form-header-container">
          <span className="form-header">
            <ArrowBackIosIcon
              className="back_button"
              onClick={() => navigate(0)}
            />
            {action === "edit" ? "Edit Banner" : "Create a new Banner"}
          </span>

          <div className="btn-container">
            <Button
              className="bordered-button"
              type="button"
              variant="contained"
              color="primary"
              onClick={CreateNewblog}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <Accordion>
        <AccordionDetails>
          <div className="form_comp">
            <label htmlFor="title">Title</label>
            <ReactQuill
              className="textField"
              type="text"
              id="title"
              placeholder="Enter Title"
              name="title"
              value={data?.title}
              onChange={(value) => setData({ ...data, title: value })}
              bounds={`[data-text-editor="name"]`}
            />
          </div>
          <div className="form_comp">
            <label htmlFor="title">Description</label>
            <ReactQuill
              className="textField"
              type="text"
              id="description"
              placeholder="Enter Sub Title"
              name="description"
              value={data?.description}
              onChange={(value) => setData({ ...data, description: value })}
              bounds={`[data-text-editor="name"]`}
            />
          </div>
          <div className="form_comp">
            <label htmlFor="title">URL</label>
            <TextField
              className="textField"
              type="text"
              id="title_url"
              placeholder="Enter Title URL"
              name="title_url"
              value={data?.meta?.title_url}
              onChange={handleChange}
            />
          </div>
          <div className="form_comp">
            <label htmlFor="category">Choose a Category</label>
            <Select
              style={{ height: "45px" }}
              name="category"
              id="category"
              onChange={getformData}
              value={data?.category}
              placeholder="all"
            >
              {category.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="form_comp">
            <div className="img-main-container">
              {/* ============== */}
              <div className="img-inner-container">
                <label htmlFor="title">Desktop Image</label>
                {!data?.src ? (
                  <>
                    {isUploadingImg ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className="bordered-button"
                        style={{ width: 200, fontSize: 14, color: "#fff" }}
                        component="label"
                      >
                        Upload Image
                        <input
                          type="file"
                          accept="image/*"
                          onChange={getSelectedFile}
                          hidden
                        />
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ maxWidth: 400 }} className="mUpload-item">
                      <img src={data?.src} width="80px" alt="img" />
                      <input
                        className="fw"
                        type="text"
                        placeholder="Enter alt tag"
                        value={data?.alt_tag}
                        onChange={handleChange}
                        name="alt_tag"
                      />
                      <DeleteIcon
                        onClick={() =>
                          setData((prevState) => ({ ...prevState, src: "" }))
                        }
                      />
                    </div>
                  </>
                )}
                {errorMessage && (
                  <span className=" mt5 create-banneer-error">
                    {errorMessage}
                  </span>
                )}
              </div>
              {/* ============== */}

              {/* ============== */}
              <div className="img-inner-container">
                <label htmlFor="title">Mobile Image</label>
                {!data?.meta?.mobile_src ? (
                  <>
                    {isUploadingImgMobile ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        className="bordered-button"
                        style={{ width: 200, fontSize: 14, color: "#fff" }}
                        component="label"
                      >
                        Upload Image
                        <input
                          type="file"
                          accept="image/*"
                          onChange={getSelectedFileMobile}
                          hidden
                        />
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ maxWidth: 400 }} className="mUpload-item">
                      <img
                        src={data?.meta?.mobile_src}
                        width="80px"
                        alt="img"
                      />
                      <input
                        className="fw"
                        type="text"
                        placeholder="Enter alt tag"
                        value={data?.meta?.mobile_alt_tag}
                        onChange={handleChange}
                        name="mobile_alt_tag"
                      />
                      <DeleteIcon
                        onClick={() =>
                          setData((prevState) => ({
                            ...prevState,
                            meta: {
                              ...prevState.meta,
                              mobile_src: "", // Set mobile_src to an empty string
                            },
                          }))
                        }
                      />
                    </div>
                  </>
                )}
                {errorMessage && (
                  <span className=" mt5 create-banneer-error">
                    {errorMessage}
                  </span>
                )}
              </div>
              {/* ============== */}
            </div>

            {action === "edit" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: "8px", marginTop: "2px" }}
                  checked={isActive}
                  onChange={handleCheckboxChange}
                />

                <label>Active</label>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={!!error}
        message={error}
        severity="error"
        autoHideDuration={2000}
      />
      <ToastContainer />
    </>
  );
};

export default CreateBanner;
