import { CloseOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

const EditCategoryItem = ({ id, qna, pullUpdateQna, editModal }) => {
  // const [editqna, setQnaEdit] = useState({
  //   id: id,
  //   question: "",
  //   answer: "",
  // });
  const [edit, setEdit] = useState();
  const [errors, setErros] = useState({});

  useEffect(() => {
    let [prefieldData] = qna?.filter((item) => item?.id == id);

    // setQnaEdit(prefieldData);
    setEdit(prefieldData);

    // if (prefieldData.length > 0) {
    //   setQnaEdit({
    //     ...editqna,
    //     question: prefieldData[0]?.questiion,
    //     answer: prefieldData[0]?.answer,
    //   });
    // }
  }, []);

  const handleQuestionChange = (value) => {
    // setQnaEdit({ ...editqna, question: value });
    setEdit((prev) => ({ ...prev, questiion: value }));
  };

  const handleAnswerChange = (value) => {
    // setQnaEdit({ ...editqna, answer: value });
    setEdit((prev) => ({ ...prev, answer: value }));
  };

  const handleSlugChange = (e) => {
    setEdit((prev) => ({ ...prev, slug: e.target.value }));
  };

  const handleMetaChange = (e) => {
    setEdit((prev) => ({ ...prev, meta_description: e.target.value }));
  };

  const handleMetaTitleChange = (e) => {
    setEdit((prev) => ({ ...prev, meta_title: e.target.value }));
  };

  const handleModal = () => {
    editModal(false);
  };

  let error = {};
  const categoryValidation = () => {
    if (edit?.questiion.replace(/(<([^>]+)>)/gi, "").length == 0) {
      error.question = "Please enter your question";
    }
    if (edit?.answer.replace(/(<([^>]+)>)/gi, "").length == 0) {
      error.answer = "Please enter your answer";
    }

    return error;
  };
  const pullData = () => {
    const validation = categoryValidation();
    setErros(validation);
    let que = edit?.questiion.replace(/(<([^>]+)>)/gi, "").length;
    let ans = edit?.answer.replace(/(<([^>]+)>)/gi, "").length;
    if (que !== 0 && ans !== 0 && validation) {
      pullUpdateQna(edit);
      handleModal();
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionDetails>
          <div className="Quil-maodal-header">
            <h3>Update Faq</h3>
            <CloseOutlined
              style={{ cursor: "pointer" }}
              onClick={() => handleModal()}
            />
          </div>
          <div className="Quil-one">
            <div className="form_comp">
              <label className="title" htmlFor="title">
                Meta Title
              </label>
              <input
                className="textField"
                type="text"
                id="title"
                placeholder="Enter Title"
                name="metaTitle"
                value={edit?.meta_title}
                onChange={(e) => handleMetaTitleChange(e)}
                bounds={`[data-text-editor="name"]`}
              />
            </div>

            <div className="form_comp">
              <label className="title" htmlFor="title">
                Meta Description
              </label>
              <input
                className="textField"
                type="text"
                id="title"
                placeholder="Enter Title"
                name="metaDescription"
                value={edit?.meta_description}
                onChange={(e) => handleMetaChange(e)}
                bounds={`[data-text-editor="name"]`}
              />
            </div>

            <div className="form_comp">
              <label className="title" htmlFor="title">
                Slug
              </label>
              <input
                className="textField"
                type="text"
                id="title"
                placeholder="Enter Title"
                name="slug"
                value={edit?.slug}
                onChange={(e) => handleSlugChange(e)}
                bounds={`[data-text-editor="name"]`}
              />
            </div>
            <div className="form_comp">
              <label className="title" htmlFor="title">
                Question
              </label>
              <ReactQuill
                className="textField"
                type="text"
                id="title"
                placeholder="Enter Title"
                name="question"
                value={edit?.questiion}
                onChange={handleQuestionChange}
                bounds={`[data-text-editor="name"]`}
              />
            </div>
            {errors?.questiion && (
              <p className="faqs-error" style={{ display: "block" }}>
                {errors.questiion}
              </p>
            )}
          </div>

          <div className="Quil-two">
            <label className="title" htmlFor="title">
              Answer
            </label>

            <div className="form_comp">
              <ReactQuill
                className="textField"
                type="text"
                id="title"
                placeholder="Enter Title"
                name="answer"
                value={edit?.answer}
                onChange={handleAnswerChange}
                bounds={`[data-text-editor="name"]`}
              />
            </div>
            {errors?.answer && (
              <p className="faqs-error" style={{ display: "block" }}>
                {errors.answer}
              </p>
            )}
          </div>
          <div className="btn-group">
            <Button
              className="quil-close-btn"
              size="medium"
              variant="outlined"
              onClick={() => handleModal()}
            >
              Close
            </Button>
            <Button size="medium" variant="contained" onClick={pullData}>
              Update
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EditCategoryItem;
