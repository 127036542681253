import axios from "axios";

import { BASE_URL } from "./constants";
import { adminInfoGetterService, adminInfoRemovalService } from "./services";
const token=localStorage.getItem("obenev-token");
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`
    },

});

// axiosInstance.interceptors.request.use(function (config) {
//   config.headers.Authorization = adminInfoGetterService();
//   return config;
// });

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 403) {
      adminInfoRemovalService();
      window.location = "/";
    } else return Promise.reject(error);
  }
);
