import React, { useState } from "react";
import {
  IconButton,
  Container,
  Drawer,
  List,
  ListItem,
  Collapse,
  Typography,
} from "@mui/material";
import { ExpandMore, ExpandLess, Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LogoImage from "../../assets/Logo.webp";
import options from "./options";
import "./style.css";

const Folder = ({ folder, navigate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItem
        button
        onClick={() => {
          navigate(folder.path);
          handleToggle();
        }}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography>{folder.name}</Typography>
        {folder.children && folder.children.length > 0 && (
          <>
            {isOpen ? (
              <ExpandLess onClick={handleToggle} />
            ) : (
              <ExpandMore onClick={handleToggle} />
            )}
          </>
        )}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ marginLeft: "20px" }}>
          {Array.isArray(folder.children) &&
            folder.children.map((item, idx) => (
              <Folder key={idx} folder={item} navigate={navigate} />
            ))}
        </List>
      </Collapse>
    </>
  );
};

const Sidebar = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const userType = localStorage.getItem("user-type");

  const navigate = useNavigate();
  const menu = (
    <List className="sidebar">
      <ListItem>
        <img
          className="sidebar-header"
          src={LogoImage}
          height="100px"
          width="95%"
          alt="Logo"
        />
      </ListItem>
      <List className="sidebar-options">
  {options?.filter((option) => option?.access?.includes(userType)) // filter options based on userType
    .map((option, idx) => (
      <Folder key={idx} folder={option} navigate={navigate} />
    ))}
</List>
    </List>
  );

  return (
    <>
      <IconButton
        className="menu-button"
        onClick={() => setIsMenuOpened((prev) => !prev)}
      >
        <Menu />
      </IconButton>
      <Drawer
        anchor="left"
        open={isMenuOpened}
        onClose={() => setIsMenuOpened(false)}
        className="sidebar-drawer"
      >
        {menu}
      </Drawer>
      <Container
        className={`sidebar-container ${isMenuOpened ? "menu-opened" : ""}`}
        disableGutters
      >
        {menu}
      </Container>
    </>
  );
};

export default Sidebar;
