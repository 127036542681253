import { Box, Button, Modal } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadImage } from "../../auth/service";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const AddLatestCityPostModal = (props) => {
  const { open, handleClose, selectedPost, handleCityPost } = props;

  const prevProp = useRef();

  const [state, setState] = useState({
    showroomName: "",
    address: "",
    city: "",
    imgAlt: "",
    src: "",
  });
  const [error, setError] = useState({});
  const [isUploadingImg, setIsUploadingImg] = useState(false);

  useEffect(() => {
    if (!prevProp?.current?.open && open) {
      setState({
        showroomName: selectedPost?.showroomName ?? "",
        address: selectedPost?.address ?? "",
        city: selectedPost?.city ?? "",
        imgAlt: selectedPost?.imgAlt ?? "",
        src: selectedPost?.src ?? "",
      });
      setError({});
    }

    return () => {
      prevProp.current = {
        open,
      };
    };
  }, [open, selectedPost]);

  const getSelectedFile = useCallback(async (e) => {
    let formData = new FormData();
    try {
      setIsUploadingImg(true);
      const file = e.target.files[0];
      formData.append("image", file);

      const res = await uploadImage(formData);
      if (res.status === "success") {
        const image = res?.data?.Location;
        setState((prevData) => ({ ...prevData, src: image }));
      }
      setError({});
    } catch (error) {
      setError("Something went wrong");
    } finally {
      setIsUploadingImg(false);
    }
  }, []);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const modalTitle = useMemo(
    () =>
      selectedPost
        ? "Edit Recently Opened Showroom"
        : "Add Recently Opened Showroom",
    [selectedPost]
  );

  const hasError = useCallback(() => {
    let { showroomName, address, city, src } = state;
    showroomName = showroomName?.trim?.();
    address = address?.trim?.();
    city = city?.trim?.();

    const error = {};

    if (!showroomName) {
      error.showroomName = "Name cannot be blank";
    }

    if (!address) {
      error.address = "Address cannot be blank";
    }
    if (!city) {
      error.city = "City cannot be blank";
    }

    if (!src) {
      error.fileList = "Upload image";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const payload = {
        ...state,
      };

      if (selectedPost && selectedPost.index !== undefined)
        payload.index = selectedPost.index;
      handleCityPost(payload);
      handleClose(false);
    }
  }, [hasError, state, selectedPost, handleCityPost, handleClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>{modalTitle}</h2>
        <div className="form_comp">
          <label htmlFor="showroomName">
            Showroom Name
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            type="text"
            placeholder="Enter showroomName"
            value={state.showroomName}
            onChange={handleChange("showroomName")}
            autoComplete="off"
          />
          <span className="error mt5">{error.showroomName}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Address
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <textarea
            rows="2"
            placeholder="Enter address"
            value={state.address}
            onChange={handleChange("address")}
          />
          <span className="error mt5">{error.address}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            City
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            type="text"
            placeholder="Enter city"
            value={state.city}
            onChange={handleChange("city")}
            autoComplete="off"
          />
          <span className="error mt5">{error.city}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Image <sup style={{ color: "red" }}>*</sup>
          </label>
          {!state?.src ? (
            <>
              {isUploadingImg ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className="bordered-button"
                  style={{ width: 200, fontSize: 14, color: "#fff" }}
                  component="label"
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getSelectedFile}
                    hidden
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              <div style={{ maxWidth: 400 }} className="mUpload-item">
                <img src={state?.src} width="80px" alt="img" />
                <input
                  className="fw"
                  type="text"
                  placeholder="Enter alt tag"
                  value={state?.imgAlt}
                  onChange={handleChange("imgAlt")}
                />
                <DeleteIcon
                  onClick={() =>
                    setState((preState) => ({ ...preState, src: "" }))
                  }
                />
              </div>
            </>
          )}
          <span className="error mt5">{error.fileList}</span>
        </div>

        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 15,
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            {selectedPost ? "Update" : "Add"}
          </Button>
          <Button
            variant="text"
            className="bordered-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddLatestCityPostModal;
