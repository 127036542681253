import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dummy } from "./Index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import {BASE_URL} from "../../../shared/constants";

const CandidateDetail = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getdetails();
    }
  }, [id]);

  const token = localStorage.getItem("obenev-token");

  const getdetails = async () => {
    const res = await axios.get(`${BASE_URL}/candidate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(res.data.data);
  };
  if (!data) {
    return <div>Loading...</div>; // Optionally handle case when data is not loaded yet
  }

  const {
    name,
    email,
    mobile,
    jobTitle,
    city,
    department,
    noticePeriod,
    coverLetter,
    date,
    resume,
  } = data;

  return (
    <div className="blogs-container">
      <div className="blog-container">
        <div className="blog-header-container">
          <span className="blog-header">
            {" "}
            <ArrowBackIosIcon
              className="back_button"
              onClick={() => navigate(-1)}
            />
            Candidate Detail - {name}
          </span>
        </div>

        <div className="cb-o-cb">
          {name && (
            <div className="i-cb">
              <strong>Name :</strong>
              <span>{name}</span>
            </div>
          )}

          {email && (
            <div className="i-cb">
              <strong>Email Address :</strong>
              <span>{email}</span>
            </div>
          )}

          {mobile && (
            <div className="i-cb">
              <strong>Mobile :</strong>
              <span>{mobile}</span>
            </div>
          )}

          {jobTitle && (
            <div className="i-cb">
              <strong>Job Title :</strong>
              <span>{jobTitle}</span>
            </div>
          )}

          {city && (
            <div className="i-cb">
              <strong>City :</strong>
              <span>{city}</span>
            </div>
          )}

          {department && (
            <div className="i-cb">
              <strong>Department :</strong>
              <span>{department}</span>
            </div>
          )}

          {noticePeriod && (
            <div className="i-cb">
              <strong>Notice Period :</strong>
              <span>{noticePeriod} Days</span>
            </div>
          )}

          {date && (
            <div className="i-cb">
              <strong>Applied Date :</strong>
              <span>{date}</span>
            </div>
          )}

          {coverLetter && (
            <div className="i-cb full-w-cb">
              <strong>Cover Letter :</strong>
              <span>{coverLetter}</span>
            </div>
          )}

          {resume && (
            <div className="i-cb full-w-cb">
              <strong>Resume :</strong>
              <a href="/test">{resume}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateDetail;
