import { axiosInstance } from "../../shared/axios";

export const adminLoginService = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/admin/login`, creds);
    return data;
  } catch (e) {
    return {
      error: "Email/Password Invalid",
    };
  }
};

export const getBlogs = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/get-blogs`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const getBanners = async (creds) => {
  try {
    const { data } = await axiosInstance.get(`/banners`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const getStore = async () => {
  try {
    const { data } = await axiosInstance.get(`/store`);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const getCities = async () => {
  try {
    const { data } = await axiosInstance.get(`/locator`);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const getNewslinks = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/get-presses`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const getblog_Detail = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/get-blogs-details`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const getbanner_Detail = async (creds) => {
  console.log("get creds", creds);
  try {
    const { data } = await axiosInstance.get(
      `/banner/${creds.bannerId}`,
      creds
    );
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const getPress_detail = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/get-presses-details`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const create_Blog = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/admin/create-blog`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const create_Banner = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/admin/banner`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const create_store = async (payload) => {
  try {
    const { data } = await axiosInstance.post(`/store`, payload);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const create_city = async (payload) => {
  try {
    const { data } = await axiosInstance.post(`/locator`, payload);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const create_Link = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`admin/create-press`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const delete_blog = async (creds) => {
  console.log("delete_banner", creds);
  try {
    const { data } = await axiosInstance.delete(`/admin/delete-blog`, {
      data: creds,
    });
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const delete_banner = async (creds) => {
  console.log("delete_banner", creds);
  try {
    const { data } = await axiosInstance.delete(
      `/admin/banner/${creds.bannerId}`,
      {
        data: creds,
      }
    );
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const delete_city = async (payload) => {
  try {
    const { data } = await axiosInstance.delete(`/locator/${payload._id}`);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const delete_link = async (creds) => {
  try {
    const { data } = await axiosInstance.delete(`/admin/delete-press`, {
      data: creds,
    });
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const edit_blog = async (creds) => {
  console.log("creds", creds);
  try {
    const { data } = await axiosInstance.put(`/admin/edit-blog`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const edit_banner = async (creds) => {
  console.log("creds", creds);
  try {
    const { data } = await axiosInstance.put(
      `/admin/banner/${creds.bannerId}`,
      creds
    );
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const edit_store = async (payload) => {
  try {
    const { data } = await axiosInstance.put(`/store/${payload._id}`, payload);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const edit_city = async (payload) => {
  try {
    const { data } = await axiosInstance.put(
      `/locator/${payload._id}`,
      payload
    );
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const edit_link = async (creds) => {
  try {
    const { data } = await axiosInstance.put(`/admin/edit-press`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const getContact_us = async () => {
  try {
    const { data } = await axiosInstance.get(`/get-contactUs`);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const get_Jobs = async () => {
  try {
    const { data } = await axiosInstance.get(`/get-jobs`);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const uploadImage = async (creds) => {
  try {
    const { data } = await axiosInstance.post(`/upload-file`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
// export const uploadBannerImage = async (creds) => {
//   try {
//     const { data } = await axiosInstance.post(`/upload-file`, creds);
//     return data;
//   } catch (e) {
//     return {
//       error: "Something went wrong",
//     };
//   }
// };

export const create_Faq_Category = async (creds) => {
  //api.obenev.com/admin/categories
  //api.obenev.com/admin/categories
  // https://api.obenev.com/testimonial
  try {
    const { data } = await axiosInstance.post(`/categories`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const update_Faq_Category = async (creds, id) => {
  //api.obenev.com/admin/categories
  //api.obenev.com/admin/categories
  console.log("cr", id);
  try {
    const { data } = await axiosInstance.put(`/categories/${id}`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const getAll_Faq_Category = async () => {
  try {
    const { data } = await axiosInstance.get(`/categories`);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const create_Testimonial = async (creds) => {
  //api.obenev.com/admin/categories
  //api.obenev.com/admin/categories
  // https://api.obenev.com/testimonial
  try {
    const { data } = await axiosInstance.post(`/testimonial`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
export const update_Testimonial = async (creds, id) => {
  // https://api.obenev.com/testimonial
  try {
    const { data } = await axiosInstance.put(`/testimonial/${id}`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const getAll_Testimonial = async (creds) => {
  //api.obenev.com/admin/categories
  //api.obenev.com/admin/categories
  // https://api.obenev.com/testimonial
  try {
    const { data } = await axiosInstance.get(`/testimonial`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};

export const getSingle_Testimonial = async (creds) => {
  //api.obenev.com/admin/categories
  //api.obenev.com/admin/categories
  // https://api.obenev.com/testimonial

  try {
    const { data } = await axiosInstance.get(`/testimonial/${creds}`, creds);
    return data;
  } catch (e) {
    return {
      error: "Something went wrong",
    };
  }
};
