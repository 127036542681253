import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import JoditEditior from "../../shared/Editor/JoditEditior";
import Loader from "../../shared/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { create_Blog, edit_blog, uploadImage } from "../auth/service";
import {
  Container,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Snackbar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import "./createForm.css";
import Add from "./assets/Add.svg";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Editor from "../../shared/Editor/Editor";
import MultipleSelect from "./accordians/MultipleSelect";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import FaqAccordian from "./accordians/FaqAccordian";

const options = [
  { value: "Trending", label: "Trending" },
  { value: "Blog", label: "Blog" },
  { value: "Performance", label: "Performance" },
  { value: "Charging", label: "Charging" },
  { value: "Battery", label: "Battery" },
  { value: "News", label: "News" },
  { value: "Tech", label: "Tech" },
  { value: "Design", label: "Design" },
];
const CreateForm = ({ action, blogdetails }) => {
  const navigate = useNavigate();
  const [editorContent, setEditorContent] = useState(
    action === "edit" ? blogdetails.content : ""
  );
  const [editormeta, setEditormeta] = useState(
    action === "edit" ? blogdetails.meta : {}
  );
  const [faqFold, setFaqFold] = useState({
    title: "",
    description: "",
    faqs: [],
  });
  const [data, setData] = useState({
    uid: "",
    title: "",
    src: "",
    imgAlt: "",
    category: "Blog",
    meta: {
      ...editormeta,
      table_content: "",
      title_url: "",
      meta_title: "",
      meta_description: "",
      faqs: "",
      altTag: ""
    },
    content: editorContent,
    bannerSrc: "",
    tags: [],
  });
  const [loading, setLoading] = useState(false);
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [error, setError] = useState("");

  // let history = useHistory();
  let formData = new FormData();
  let formBannerData = new FormData();
  const getSelectedFile = async (e) => {
    try {
      setIsUploadingImg(true);
      const file = e.target.files[0];
      formData.append("image", file);

      const res = await uploadImage(formData);
      if (res.status === "success") {
        const image = res?.data?.Location;
        setData((prevData) => ({ ...prevData, src: image }));
      } else {
      }
    } catch (error) {
      setError("Something went wrong");
    } finally {
      setIsUploadingImg(false);
    }

    // else{
    //     // return <Loader />;
    //     setLoading(true)
    // }
  };
  // const getSelectedBannerFile = async (e) => {
  //   setLoading(true);
  //   var file = e.target.files[0];
  //   formBannerData.append("image", file);
  //   const res = await uploadImage(formBannerData);
  //   if (res.status == "success") {
  //     const image = res?.data?.Location;
  //     setData({ ...data, bannerSrc: image });
  //     setLoading(false);
  //   }
  //   // else{
  //   //     // return <Loader />;
  //   //     setLoading(true)
  //   // }
  // };

  // const goBack = () => {
  //   // history.goBack()
  // }
  const getformData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "uid") {
      // Update uid directly
      setData((prevData) => ({
        ...prevData,
        [name]: value.trim(),
      }));
    }
    else if (name === "altTag") {
      // Update alttag inside both data.meta
      setData((prevData) => ({
        ...prevData,
        meta: { ...prevData.meta, altTag: value },
      }));
    } else if (name === "title_url") {
      // Update title_url inside both data.meta and editormeta
      setData((prevData) => ({
        ...prevData,
        meta: { ...prevData.meta, title_url: value.trim() },
      }));
      setEditormeta((prevMeta) => ({
        ...prevMeta,
        title_url: value.trim(),
      }));
    } else if (name === "meta_title") {
      // Update meta_title inside data.meta
      setData((prevData) => ({
        ...prevData,
        meta: { ...prevData.meta, meta_title: value },
      }));
    } else if (name === "meta_description") {
      // Update meta_description inside data.meta
      setData((prevData) => ({
        ...prevData,
        meta: { ...prevData.meta, meta_description: value },
      }));
    } else {
      // Update other fields
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const editTitle = (e) => {
    // const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, title: value });
  };
  const editSubTitle = (e) => {
    // const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, subTitle: value });
  };

  const CreateNewblog = async () => {
    const missingFields = mandatoryFields.filter((field) => !data[field]);
    if (missingFields.length > 0) {
      const errorMessage = `Please fill in all required fields:`;

      toast.error(errorMessage);
    }
    let bugs = false;
    try {
      let resp;
      if (action === "edit") {
        console.log(data, "Data ============")
        const res = await edit_blog({
          ...data,
          content: editorContent,
          meta: {
            table_content: editormeta.table_content || "",
            title_url: data?.meta?.title_url || "",
            meta_title: data?.meta?.meta_title || "",
            meta_description: data?.meta?.meta_description || "",
            meta_faqs: faqFold || "",
            altTag: data?.meta?.altTag || ""
          },
          blogId: blogdetails?._id,
        });
        resp = res;
        console.log("rs", resp);
      } else {
        const res = await create_Blog({
          ...data,
          content: editorContent,
          meta: {
            table_content: editormeta.table_content || "",
            title_url: data?.meta?.title_url || "",
            meta_title: data?.meta?.meta_title || "",
            meta_description: data?.meta?.meta_description || "",
            meta_faqs: faqFold || "",
            altTag: data?.meta?.altTag || ""
          },
        });
        resp = res;
      }

      if (!resp.error) {
        toast.success(resp.message);
        window.location.reload();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    setFaqFold((prevState) => ({
      ...prevState,
      title: blogdetails?.meta?.meta_faqs?.title || "",
      description: blogdetails?.meta?.meta_faqs?.description || "",
      faqs: blogdetails?.meta?.meta_faqs?.faqs || "",
    }));
  }, [blogdetails, setFaqFold]);
  useEffect(() => {
    if (action === "edit") {
      console.log("blogdetails.meta:", blogdetails.meta);
      setData({
        ...data,
        title: blogdetails.title,
        subTitle: blogdetails.subTitle,
        content: editorContent,
        meta: {
          ...blogdetails.meta,
          table_content: blogdetails.meta?.table_content || "",
          title_url: blogdetails.meta?.title_url || "",
          meta_title: blogdetails?.meta?.meta_title || "",
          meta_description: blogdetails?.meta?.meta_description || "",
          altTag: blogdetails?.meta?.altTag || ""
        },
        category: blogdetails.category,
        imgAlt: blogdetails.imgAlt,
        src: blogdetails.src,
        bannerSrc: blogdetails.bannerSrc,
        uid: blogdetails.uid || blogdetails._id,
        tags: blogdetails.tags || [],
      });
    }
  }, [action, blogdetails, editorContent, editormeta]);

  const category = [
    "Trending",
    "Performance",
    "Charging",
    "Battery",
    "Design",
    "Tech",
    "News",
  ];

  if (loading) {
    return <Loader />;
  }

  const handleChangeEditorData = (newContent) => {
    try {
      const parsedMeta = JSON.parse(newContent);
      setEditormeta((prevMeta) => ({
        ...prevMeta,
        table_content: parsedMeta.table_content || "",
        title_url: "",
        meta_title: "",
        meta_description: "",
        altTag: ""
      }));
    } catch (error) {
      console.error("Error parsing meta JSON:", error);
    }
  };

  const mandatoryFields = ["title", "subTitle", "uid", "category"];

  const handleTagChange = (selectedOptions) => {
    const tags = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setData((prevData) => ({ ...prevData, tags }));
  };

  const handlePullData = (faqsData) => {
    setFaqFold((prevState) => ({
      ...prevState,
      ...faqsData,
    }));

    // setData((prevData) => ({
    //   ...prevData,
    //   meta: {
    //     ...prevData.meta,
    //     faqs: faqsData, // new value for faqs array
    //   },
    // }));
  };

  return (
    <>
      <div className="create-form--container">
        <div className="form-header-container">
          <span className="form-header">
            <ArrowBackIosIcon
              className="back_button"
              onClick={() => navigate(0)}
            />
            {action === "edit" ? "Edit Blog" : "Create a new Blog"}
          </span>

          <div className="btn-container">
            <Button
              className="bordered-button"
              type="button"
              variant="contained"
              color="primary"
              onClick={CreateNewblog}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={"600"} className="fw800">
            Blog Meta Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="form_comp">
            <label htmlFor="meta_title">Meta Title</label>
            <TextField
              className="textField"
              type="text"
              id="meta_title"
              placeholder="Enter Title URL"
              name="meta_title"
              value={data?.meta?.meta_title || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form_comp">
            <label htmlFor="meta_description">Meta Description</label>
            <TextField
              className="textField"
              type="text"
              id="meta_description"
              placeholder="Enter Title URL"
              name="meta_description"
              value={data?.meta?.meta_description || ""}
              onChange={handleChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionDetails>
          <Container
            sx={{
              marginTop: "1rem",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              padding: "0px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "60%", lg: "60%" },
                display: "flex",
                flexDirection: "column",
                padding: "0px 0px 0px 0px",
              }}
            >
              <div className="form_comp">
                <label htmlFor="title">Title</label>
                <TextField
                  className="textField"
                  type="text"
                  id="title"
                  placeholder="Enter Title"
                  name="title"
                  value={data?.title}
                  onChange={editTitle}
                />
              </div>

              <Grid container spacing={2} order={{ xs: 2, md: 1 }}>
                <Grid item xs={12} md={6}>
                  <div className="form_comp form_comp_subtitle">
                    <label htmlFor="subtitle">Sub Title</label>
                    <TextField
                      className="textField"
                      type="text"
                      id="subtitle"
                      placeholder="Enter Sub Title"
                      name="subtitle"
                      value={data?.subTitle}
                      onChange={editSubTitle}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="form_comp form_comp_subtitle">
                    <label htmlFor="slug">Slug</label>
                    <TextField
                      className="textField"
                      type="text"
                      id="title"
                      placeholder="Enter Slug"
                      name="uid"
                      value={data?.uid}
                      onChange={handleChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_comp">
                    <label htmlFor="category">Choose a Category</label>
                    <Select
                      style={{ height: "45px" }}
                      name="category"
                      id="category"
                      onChange={getformData}
                      value={data?.category || ""}
                      displayEmpty
                      placeholder="Select a category"
                    >
                      <MenuItem value="Select a category" disabled>
                        Choose a Category
                      </MenuItem>
                      {category.map((el) => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="form_comp">
                    <label htmlFor="category">Choose a Popular Tags</label>
                    <MultipleSelect
                      options={options}
                      onChange={handleTagChange}
                      existingTags={data.tags}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "30%" },
                height: "270px",
              }}
            >
              {data?.src ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      //display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <div
                      style={{ width: "max-content" }}
                      onClick={() => setData({ ...data, src: "" })}
                    >
                      <CancelIcon />
                    </div>
                  </div>
                  <img src={data?.src} height="247px" width="100%" alt="img" />
                  <input
                    style={{
                      height: "40px",
                      width: "100%",
                      display: "flex",
                    }}
                    className="alt-tag-with-img"
                    type="text"
                    placeholder="Enter alt tag"
                    name="altTag"
                    value={data?.meta?.altTag}
                    onChange={handleChange}
                  />
                </>
              ) : (
                <div sx={{ textAlign: "center" }}>
                  {isUploadingImg ? (
                    <Loader />
                  ) : (
                    <React.Fragment>
                      <label htmlFor="kyc_doc">
                        <div
                          style={{
                            height: "250px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <img src={Add} alt="upload img" />
                        </div>
                      </label>

                      <input
                        type="file"
                        id="kyc_doc"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={getSelectedFile}
                      />
                    </React.Fragment>
                  )}
                </div>
              )}
            </Box>

            <div className="form_comp" style={{ width: "92%" }}>
              <label htmlFor="description">Table Of Contents</label>

              <JoditEditior
                style={{ height: "300px", border: "2px" }}
                content={editormeta.table_content || ""}
                value={editormeta.table_content || ""}
                setContent={(content) =>
                  setEditormeta({ ...editormeta, table_content: content })
                }
                onChange={(newContent) => handleChangeEditorData(newContent)}
              />
            </div>
            <div className="form_comp" style={{ width: "92%" }}>
              <label htmlFor="description">Description</label>

              <JoditEditior
                style={{ height: "300px", border: "2px" }}
                content={editorContent}
                value={data.content}
                setContent={setEditorContent}
                onChange={() => handleChangeEditorData}
              />
            </div>
          </Container>
        </AccordionDetails>
      </Accordion>

      <div className="blog-faq">
        <FaqAccordian
          data={faqFold}
          title={"FAQs"}
          setData={setFaqFold}
          pullData={handlePullData}
          metaData={data}
        />
      </div>

      <Snackbar
        open={!!error}
        message={error}
        severity="error"
        autoHideDuration={2000}
      />
      <ToastContainer />
    </>
  );
};

export default CreateForm;
