import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import BannerAccordion from "../accordians/BannerAccordion";
import RecentShowroomAccordian from "../accordians/RecentShowroomAccordian";
import TestimonialAccordian from "../accordians/TestimonialAccordian";
import PageMetaAccordian from "../accordians/PageMetaAccordian";
import FaqAccordian from "../accordians/FaqAccordian";
import ShowroomAccordian from "../accordians/ShowroomAccordian";
import ServiceCentreAccordian from "../accordians/ServiceCentreAccordian";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { cityActions } from "../duck/CityReducer";
import Loader from "../../../shared/Loader";

const EditStorePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeId = useParams()?.id;

  const { loading } = useSelector(({ city }) => city);
  const selectedCity = useSelector(({ city }) => city?.citiesMap?.[storeId]);

  const [pageParams, setPageParams] = useState({
    metaTitle: "",
    metaDescription: "",
  });
  const [bannerFold, setBannerFold] = useState({
    imgTitle: "",
    imgDescription: "",
    imgAlt: "",
  });
  const [recentShowroomFold, setRecentShowroomFold] = useState({
    title: "",
    description: "",
    recentShowrooms: [],
  });
  const [showroomFold, setShowroomFold] = useState({
    title: "",
    description: "",
    showrooms: [],
  });
  const [serviceCentreFold, setserviceCentreFold] = useState({
    title: "",
    description: "",
    serviceCentres: [],
  });
  const [testimonialFold, setTestimonialFold] = useState({
    title: "",
    description: "",
    testimonials: [],
  });
  const [faqFold, setFaqFold] = useState({
    title: "",
    description: "",
    faqs: [],
  });

  useEffect(() => {
    dispatch(cityActions.onGetCities());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCity?.meta) {
      //const meta = JSON.parse(selectedCity?.meta);
      const meta =
        typeof selectedCity?.meta === "string"
          ? JSON.parse(selectedCity?.meta)
          : selectedCity?.meta;

      meta?.pageParams && setPageParams(meta?.pageParams);
      meta?.fold_1 && setBannerFold(meta?.fold_1);
      meta?.fold_2a && setShowroomFold(meta?.fold_2a);
      meta?.fold_2b && setserviceCentreFold(meta?.fold_2b);
      meta?.fold_3 && setRecentShowroomFold(meta?.fold_3);
      meta?.fold_4 && setTestimonialFold(meta?.fold_4);
      meta?.fold_5 && setFaqFold(meta?.fold_5);
    }
  }, [selectedCity]);

  const onSave = useCallback(() => {
    const extraPayload = {
      pageParams,
      fold_1: bannerFold,
      fold_2a: showroomFold,
      fold_2b: serviceCentreFold,
      fold_3: recentShowroomFold,
      fold_4: testimonialFold,
      fold_5: faqFold,
    };

    const payload = {
      _id: selectedCity?._id,
      meta: extraPayload,
    };

    dispatch(cityActions.onUpdateCity(payload));
  }, [
    bannerFold,
    dispatch,
    faqFold,
    pageParams,
    recentShowroomFold,
    selectedCity?._id,
    serviceCentreFold,
    showroomFold,
    testimonialFold,
  ]);

  if (!storeId) {
    navigate(-1);
  }
  if (storeId && !loading && !selectedCity?._id) {
    return <>Not Found</>;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="store-container">
          <div className="header-container">
            <span className="header">
              <ArrowBackIosIcon
                className="back_button"
                onClick={() => navigate(-1)}
              />
              {selectedCity?.city} Store Details
            </span>
            <div className="btn-container">
              <Button
                variant="contained"
                className="bordered-button"
                onClick={onSave}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="store">
            <PageMetaAccordian
              title="Page Meta Details"
              data={pageParams}
              setData={setPageParams}
            />
            <BannerAccordion
              title={"Fold 1 - Banner Image"}
              data={bannerFold}
              setData={setBannerFold}
            />
            <ShowroomAccordian
              title={"Fold 2a - Showrooms"}
              storeType={"Showroom"}
              data={showroomFold}
              setData={setShowroomFold}
            />
            <ServiceCentreAccordian
              title={"Fold 2b - Service Centres"}
              storeType={"Service Centre"}
              data={serviceCentreFold}
              setData={setserviceCentreFold}
            />
            <RecentShowroomAccordian
              title={"Fold 3 - Recently Opened Showroom"}
              data={recentShowroomFold}
              setData={setRecentShowroomFold}
            />
            <TestimonialAccordian
              title={"Fold 4 - Testimonial"}
              data={testimonialFold}
              setData={setTestimonialFold}
            />
            <FaqAccordian
              title={"Fold 5 - FAQs"}
              data={faqFold}
              setData={setFaqFold}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EditStorePage;
