import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const UserTable = ({ data, setView, setAction, setCreateManager, deleteJob }) => {
  const [open, setOpen] = useState(false);
  const [delId, setDelId] = useState("");
  const navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/hr-portal/job-detail/${id}`); // Assuming this route will show the candidate detail
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setDelId(id);
  };

  const getDetails = (detail) => {
    // Function to handle getting details, currently not implemented
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650, px: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Qualification</TableCell>
              <TableCell align="left">Experience</TableCell>
              <TableCell align="left">View</TableCell>
              <TableCell align="left">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => {
              const { _id, date, job_title, job_type, category, qualification, experience } = row;
              return (
                <TableRow
                  key={_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{date}</TableCell>
                  <TableCell align="left">{job_title}</TableCell>
                  <TableCell align="left">{job_type}</TableCell>
                  <TableCell align="left">{category}</TableCell>
                  <TableCell align="left">{qualification}</TableCell>
                  <TableCell align="left">
                    {experience}
                    {/*<Link href={resume} target="_blank" rel="noopener">*/}
                    {/*  View Resume*/}
                    {/*</Link>*/}
                  </TableCell>
                  <TableCell align="left" onClick={() => handleView(_id)}>
                    <VisibilityIcon style={{ cursor: "pointer" }} />
                  </TableCell>
                  <TableCell align="left" onClick={() => handleOpen(_id) }>
                    <DeleteIcon style={{ cursor: "pointer" }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for delete confirmation */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this entry?
          </Typography>
          <Box mt={2}>
            <button onClick={handleClose}>Cancel</button>
            <button
              onClick={() => {
                // Handle delete logic here
                console.log(`Deleted entry with ID: ${delId}`);
                deleteJob(delId)
                handleClose();
              }}
            >
              Confirm
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserTable;
