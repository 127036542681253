import { put, takeLatest } from "redux-saga/effects";
import { storeActions } from "./StoreReducer";
import { create_store, edit_store, getStore } from "../../auth/service";

const {
  onGetStore,
  onUpdateStore,
  onCreateStore,
  onHandleSuccess,
  onHandleError,
} = storeActions;

function* getStoreRequest(action) {
  const res = yield getStore();
  if (!res.error && Array.isArray(res?.data)) {
    yield put(onHandleSuccess(res?.data?.[0]));
  } else {
    console.log("get store error ", res.error);
    yield put(onHandleError(res.error));
  }
}
function* updateStoreRequest(action) {
  const res = yield edit_store(action.payload);
  if (!res.error) {
    yield put(onHandleSuccess(res?.data));
  } else {
    console.log("update store error ", res.error);
    yield put(onHandleError(res.error));
  }
}
function* createStoreRequest(action) {
  const res = yield create_store(action.payload);
  if (!res.error) {
    yield put(onHandleSuccess(res?.data));
  } else {
    console.log("create store error ", res.error);
    yield put(onHandleError(res.error));
  }
}

function* storeSaga() {
  yield takeLatest(onGetStore.type, getStoreRequest);
  yield takeLatest(onUpdateStore.type, updateStoreRequest);
  yield takeLatest(onCreateStore.type, createStoreRequest);
}

export default storeSaga;
