import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getNewslinks } from "../auth/service";
import UserTable from "../Common/UserTable";
import CreateForm from "./Createlink";
import "../ContactUs/contactPage.css";
const NewsLink = () => {
  const [createManager, setCreateManager] = useState(false);
  const [Edit_detail, setEdit] = useState({});
  const [action, setAction] = useState("");
  const createRole = () => {
    setCreateManager(true);
  };
  const [data, setData] = useState([]);
  const getAllNewslinks = async () => {
    const res = await getNewslinks({ category: "" });
    if (res?.data) {
      setData(res?.data);
    }
  };
  const getAllNewslinksMilestone = async () => {
    const res = await getNewslinks({ category: "Milestone" });
    if (res?.data) {
      setData(res?.data);
    }
  };
  const getAllNewslinksCorporate = async () => {
    const res = await getNewslinks({ category: "Corporate" });
    if (res?.data) {
      setData(res?.data);
    }
  };
  const getAllNewslinksProduct = async () => {
    const res = await getNewslinks({ category: "Product Review" });
    if (res?.data) {
      setData(res?.data);
    }
  };
  useEffect(() => {
    getAllNewslinks();
  }, []);
  return (
    <div className="contact-container">
      {createManager ? (
        <CreateForm action={action} linkdetails={Edit_detail} />
      ) : (
        <>
          <div className="blog-container">
            <div className="blog-header-container">
              <span className="blog-header"> Press Release</span>

              <div className="btn-container">
                <Button
                  variant="contained"
                  className="bordered-button"
                  onClick={createRole}
                >
                  Create a News link
                </Button>
              </div>
            </div>
          </div>
          <FormControl
            sx={{ m: 1, marginLeft: "-0.1rem", minWidth: 200 }}
            size="small"
          >
            <InputLabel id="demo-select-small">Filter on categories</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              // value={age}
              label="Filter on categories"
              // onChange={handleChange}
            >
              {/* <MenuItem value="">Trending
                <em>All</em>
              </MenuItem> */}
              <MenuItem value={10} onClick={getAllNewslinks}>
                All Mentions
              </MenuItem>
              <MenuItem value={30} onClick={getAllNewslinksCorporate}>
                Corporate
              </MenuItem>
              <MenuItem value={20} onClick={getAllNewslinksMilestone}>
                Milestone
              </MenuItem>
              <MenuItem value={40} onClick={getAllNewslinksProduct}>
                Product Review
              </MenuItem>
            </Select>
          </FormControl>

          <UserTable
            data={data}
            type="Link"
            setEdit={setEdit}
            setAction={setAction}
            setCreateManager={setCreateManager}
          />
        </>
      )}
    </div>
  );
};

export default NewsLink;
