export const dummy = [
  {
    id: 1,
    date: "2024-09-30",
    name: "Jane Smith",
    email: "janesmith@example.com",
    mobile: "+0987654321",
    jobTitle: "Data Scientist",
    resume: "Link to Jane's resume",
    city: "New Delhi",
    department: "IT Services",
    noticePeriod: "30",
    coverLetter:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...",
  },
  {
    id: 2,
    date: "2024-10-01",
    name: "John Doe",
    email: "johndoe@example.com",
    mobile: "+1234567890",
    jobTitle: "Software Engineer",
    resume: "Link to John's resume",
    city: "San Francisco",
    department: "Software Development",
    noticePeriod: "60",
    coverLetter:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget ex ultricies, tincidunt ligula in, malesuada nulla...",
  },
  {
    id: 3,
    date: "2024-10-02",
    name: "Alice Johnson",
    email: "alicejohnson@example.com",
    mobile: "+1987654321",
    jobTitle: "UX Designer",
    resume: "Link to Alice's resume",
    city: "Berlin",
    department: "Design",
    noticePeriod: "45",
    coverLetter:
      "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Etiam in felis vel velit elementum dapibus...",
  },
  {
    id: 4,
    date: "2024-10-03",
    name: "Michael Brown",
    email: "michaelbrown@example.com",
    mobile: "+1456789123",
    jobTitle: "Product Manager",
    resume: "Link to Michael's resume",
    city: "Toronto",
    department: "Product",
    noticePeriod: "90",
    coverLetter:
      "Phasellus auctor metus nec nulla dapibus, nec gravida leo pellentesque. Curabitur et neque ut nulla faucibus tempus...",
  },
  {
    id: 5,
    date: "2024-10-04",
    name: "Emma Davis",
    email: "emmadavis@example.com",
    mobile: "+1123456789",
    jobTitle: "Marketing Specialist",
    resume: "Link to Emma's resume",
    city: "London",
    department: "Marketing",
    noticePeriod: "30",
    coverLetter:
      "Suspendisse potenti. Nam cursus, magna nec viverra consequat, lectus elit varius leo, eget tempus odio orci at mauris...",
  },
];

export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];

export const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
