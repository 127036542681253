import React, { useCallback, useEffect, useState } from "react";
import PageMetaAccordian from "./accordians/PageMetaAccordian";
import BannerAccordion from "./accordians/BannerAccordion";
import BasicAccordian from "./accordians/BasicAccordian";
import TestimonialAccordian from "./accordians/TestimonialAccordian";
import RecentShowroomAccordian from "./accordians/RecentShowroomAccordian";
import AddCityAccordian from "./accordians/AddCityAccordian";
import "./StoreLocattion.css";
import { Button } from "@mui/material";
import { storeActions } from "./duck/StoreReducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../shared/Loader";

const StoreLocattion = () => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(({ store }) => store);

  const [pageParams, setPageParams] = useState({
    metaTitle: "",
    metaDescription: "",
  });
  const [bannerFold, setBannerFold] = useState({
    imgTitle: "",
    imgDescription: "",
    imgAlt: "",
  });
  const [basicFold, setBasicFold] = useState({
    title: "",
    description: "",
  });
  const [recentShowroomFold, setRecentShowroomFold] = useState({
    title: "",
    description: "",
    recentShowrooms: [],
  });
  const [testimonialFold, setTestimonialFold] = useState({
    title: "",
    description: "",
    testimonials: [],
  });

  useEffect(() => {
    dispatch(storeActions.onGetStore());
  }, [dispatch]);

  useEffect(() => {
    if (data?.meta) {
      const meta =
        typeof data?.meta === "string" ? JSON.parse(data?.meta) : data?.meta;

      meta?.pageParams && setPageParams(meta?.pageParams);
      meta?.fold_1 && setBannerFold(meta?.fold_1);
      meta?.fold_2 && setBasicFold(meta?.fold_2);
      meta?.fold_3 && setRecentShowroomFold(meta?.fold_3);
      meta?.fold_4 && setTestimonialFold(meta?.fold_4);
    }
  }, [data]);

  const onSave = useCallback(() => {
    const extraPayload = {
      pageParams,
      fold_1: bannerFold,
      fold_2: basicFold,
      fold_3: recentShowroomFold,
      fold_4: testimonialFold,
    };

    const payload = {
      meta: extraPayload,
    };

    if (data?._id) {
      payload._id = data._id;
      dispatch(storeActions.onUpdateStore(payload));
    } else {
      dispatch(storeActions.onCreateStore(payload));
    }
  }, [
    pageParams,
    bannerFold,
    basicFold,
    recentShowroomFold,
    testimonialFold,
    data,
    dispatch,
  ]);

  console.log(data, "hello=============================");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="store-container">
          <div className="header-container">
            <span className="header">Store Locations</span>

            <div className="btn-container">
              <Button
                variant="contained"
                className="bordered-button"
                onClick={onSave}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="stores">
            <PageMetaAccordian
              title="Page Meta Details"
              data={pageParams}
              setData={setPageParams}
            />
            <BannerAccordion
              title={"Fold 1 - Banner Image"}
              data={bannerFold}
              setData={setBannerFold}
            />
            <BasicAccordian
              title={"Fold 2 - SEO Friendly"}
              data={basicFold}
              setData={setBasicFold}
            />
            <RecentShowroomAccordian
              title={"Fold 3 - Recently Opened Showroom"}
              data={recentShowroomFold}
              setData={setRecentShowroomFold}
            />
            <TestimonialAccordian
              title={"Fold 4 - Testimonial"}
              data={testimonialFold}
              setData={setTestimonialFold}
            />
            <AddCityAccordian title={"Add City"} expanded={true} />
          </div>
        </div>
      )}
    </>
  );
};

export default StoreLocattion;
