import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { dummy, colourOptions, options } from "./Index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import JoditEditior from "../../../shared/Editor/JoditEditior";
import axios from "axios";
import {BASE_URL} from "../../../shared/constants";
import {Button} from "@mui/material";

const JobDetail = () => {
  const [data, setData] = useState(null); // Holds job data
  const { id } = useParams();
  const navigate = useNavigate();

  // Fetch data based on ID
  useEffect(() => {
    if (id) {
      getdetails();
    }
  }, [id]);

const token = localStorage.getItem("obenev-token");

const getdetails = async () => {
  const res = await axios.get(`${BASE_URL}/jobs/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const payload = {
    category: res.data?.data?.category,
    jobTitle: res.data?.data?.job_title,
    jobType: res.data?.data?.job_type,
    jobDescription: res.data?.data?.job_description,
    experience: res.data?.data?.experience,
    qualification: res.data?.data?.qualification,
    preferredLocation: res.data?.data?.location,
    tags: res.data?.data?.tags,
  }
  setData(payload);
};

  // Handler for input and select changes
  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;
      setData((prevState) => ({ ...prevState, [name]: value }));
    },
    [setData]
  );

  if (!data) {
    return <div>Loading...</div>; // Shows a loading state when data is not available yet
  }

  const submitForm = ()=> {
    console.log("--------->",data)
    const payload = {
      category: data?.category?.value || data?.category,
      jobTitle: data?.jobTitle?.value || data?.jobTitle,
      jobType: data?.jobType?.value || data?.jobType,
      jobDescription: data?.jobDescription,
      experience: data?.experience,
      qualification: data?.qualification,
      preferredLocation: data?.preferredLocation?.value || data?.preferredLocation,
      tags: data?.tags,
    }
    axios.put(`${BASE_URL}/jobs/${id}`, {data: payload},
        //
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // }
    ).then(res=>{
      console.log(res.data.data);
      navigate(-1)
    }).catch(err=>{
      alert("Error updating Job Create Job");
    });
  }

  return (
    <div className="blogs-container">
      <div className="blog-container" style={{ overflow: "initial" }}>
        <div className="blog-header-container">
          <span className="blog-header">
            <ArrowBackIosIcon
              className="back_button"
              onClick={() => navigate(-1)}
            />
            Job Detail
          </span>
        </div>

        <div className="form_o_cb">
          <div className="form_row">
            {/* Job Title */}
            <div className="form_col_6 form_container_box">
              <label>Job Title</label>
              <Select
                options={options}
                value={options.find((opt) => opt.value === data.jobTitle)} // Binding selected value
                onChange={handleChange("jobTitle")} // Updates state when selecting job type
              />
            </div>

            {/* Job Type */}
            <div className="form_col_6 form_container_box">
              <label>Job Type</label>
              <Select
                options={options}
                value={options.find((opt) => opt.value === data.jobType)} // Binding selected value
                onChange={handleChange("jobType")} // Updates state when selecting job type
              />
            </div>

            {/* Category */}
            <div className="form_col_6 form_container_box">
              <label>Category</label>
              <Select
                options={options}
                value={options.find((opt) => opt.value === data.category)} // Binding selected value
                onChange={handleChange("category")} // Updates state when selecting job type
              />
            </div>

            {/* Tags */}
            <div className="form_col_6 form_container_box">
              <label>Tags</label>
              <Select
                value={data?.tags}
                isMulti
                name="colors"
                options={colourOptions}
                classNamePrefix="select"
                onChange={handleChange("tags")} // Handling tags selection
              />
            </div>

            {/* Qualification */}
            <div className="form_col_6 form_container_box">
              <label>Qualification</label>
              <input
                type="text"
                className="set_height"
                placeholder="Qualification"
                autoComplete="off"
                value={data.qualification || ""}
                onChange={handleChange("qualification")} // Binding state
              />
            </div>

            {/* Job for preferred Location */}
            <div className="form_col_6 form_container_box">
              <label>Job for Preferred Location</label>
              <div className="set_height">
                <Select
                  options={options}
                  value={options.find(
                    (opt) => opt.value === data.preferredLocation
                  )}
                  onChange={handleChange("preferredLocation")} // Binding state
                />
              </div>
            </div>

            {/* Experience */}
            <div className="form_col_6 form_container_box">
              <label>Experience</label>
              <input
                type="text"
                className="set_height"
                placeholder="Experience in months or years"
                autoComplete="off"
                value={data.experience || ""}
                onChange={handleChange("experience")} // Binding state
              />
            </div>
          </div>

          {/* Job Description */}
          <div className="form_comp">
            <label htmlFor="title">Job Description</label>
            <JoditEditior
              content={data.jobDescription || ""}
              onChange={handleChange("jobDescription")}
              setContent={""}
              config={{
                minHeight: 600,
              }}
              // Handling editor changes
            />
          </div>

        <Button
            variant="contained"
            className="bordered-button"
            onClick={submitForm}
        >
          Update
        </Button>
        <Button
            variant="contained"
            className="bordered-button"
            onClick={()=>navigate(-1)}
        >
          Close
        </Button>
        </div>
      </div>
    </div>
  );
};

export default JobDetail;
