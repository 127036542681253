import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PageMetaAccordian = (props) => {
  const { title, data, setData } = props;

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setData((preState) => ({ ...preState, [name]: value }));
    },
    [setData]
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography fontWeight={"600"} className="fw800">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="form_comp">
          <label htmlFor="title">Meta Title</label>

          <input
            type="text"
            placeholder="Meta title"
            value={data?.metaTitle}
            onChange={handleChange("metaTitle")}
            autoComplete="off"
          />
        </div>
        <div className="form_comp">
          <label htmlFor="title">Meta Description</label>
          <textarea
            rows="2"
            placeholder="Meta description"
            value={data?.metaDescription}
            onChange={handleChange("metaDescription")}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default PageMetaAccordian;
