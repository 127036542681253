import { put, takeLatest } from "redux-saga/effects";
import {
  create_city,
  edit_city,
  getCities,
  delete_city,
} from "../../auth/service";
import { cityActions } from "./CityReducer";

const {
  onGetCities,
  onUpdateCity,
  onCreateCity,
  onDeleteCity,
  onHandleSuccess,
  onCreateOneSuccess,
  onUpdateOneSuccess,
  onDeleteOneSuccess,
  onHandleError,
} = cityActions;

function* getCityRequest(action) {
  const res = yield getCities();
  if (!res.error && Array.isArray(res?.data)) {
    yield put(onHandleSuccess({ data: res?.data }));
  } else {
    console.log("get city error ", res.error);
    yield put(onHandleError(res.error));
  }
}
function* updateCityRequest(action) {
  const res = yield edit_city(action.payload);
  if (!res.error) {
    yield put(onUpdateOneSuccess({ data: res?.data }));
  } else {
    console.log("update city error ", res.error);
    yield put(onHandleError(res.error));
  }
}
function* createCityRequest(action) {
  const res = yield create_city(action.payload);
  if (!res.error) {
    yield put(onCreateOneSuccess({ data: res?.data }));
  } else {
    console.log("create city error ", res.error);
    yield put(onHandleError(res.error));
  }
}
function* deleteCityRequest(action) {
  const res = yield delete_city(action.payload);
  if (!res.error) {
    yield put(onDeleteOneSuccess({ data: res?.data }));
  } else {
    console.log("create city error ", res.error);
    yield put(onHandleError(res.error));
  }
}

function* citySaga() {
  yield takeLatest(onGetCities.type, getCityRequest);
  yield takeLatest(onUpdateCity.type, updateCityRequest);
  yield takeLatest(onCreateCity.type, createCityRequest);
  yield takeLatest(onDeleteCity.type, deleteCityRequest);
}

export default citySaga;
