import React, { useEffect, useState } from "react";
import UserTable from "./UserTable";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "@mui/material";
import { dummy } from "./Index";
import CreateJobModelLayout from "./CreateJobModelLayout";
import axios from "axios";
import {BASE_URL} from "../../../shared/constants";

const JobListing = () => {
  const [createJobModel, setCreateJobModel] = useState(false);
  const [action, setAction] = useState("");
  const [data, setData] = useState([]);
  useEffect(()=>{
    getJobList()
  }, [createJobModel])

  const getJobList = async () => {
   try {
     const res =  await axios.get(`${BASE_URL}/jobs`)
     setData(res?.data?.data)
   } catch (e) {
     alert("error in get job listing")
   }
  }
  const deleteJob = async (id) => {
    try {
      const res =  await axios.delete(`${BASE_URL}/jobs/${id}`)
      getJobList()
    } catch (e) {
      alert("error in delete job")
    }
  }
  const createJob = () => {
    setCreateJobModel(true);
  };
  //const [data, setData] = useState(dummy);

  console.log(data)
  return (
    <div className="blogs-container">
      <>
        <div className="blog-container">
          <div className="blog-header-container">
            <span className="blog-header">Job Listing</span>
            <div className="btn-container">
              <Button
                  variant="contained"
                  className="bordered-button"
                  onClick={createJob}
              >
                Create a Job
              </Button>
            </div>
          </div>
        </div>
        <Modal className={'blog-container'} style={{backgroundColor:"white"}} open={createJobModel} >
          <CreateJobModelLayout onClose={() => setCreateJobModel(false)} />
        </Modal>
        <UserTable
            data={data}
            setAction={setAction}
            deleteJob={deleteJob}
            setCreateManager={setCreateJobModel}
        />
      </>
    </div>
  );
};

export default JobListing;
