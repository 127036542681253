import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddShowRoomModal from "../components/AddShowRoomModal";
import ReactQuill from "react-quill";

const ServiceCentreAccordian = (props) => {
  const { title, storeType, data, setData } = props;
  const prevProps = useRef();
  const [selectedStore, selectStore] = useState(null);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [serviceCentres, setServiceCentres] = useState([]);

  useEffect(() => {
    setServiceCentres(data?.serviceCentres);
  }, [data?.serviceCentres]);

  useEffect(() => {
    if (JSON.stringify(serviceCentres) !== prevProps?.current?.serviceCentres) {
      setData((preState) => ({ ...preState, serviceCentres }));
    }

    return () => {
      prevProps.current = {
        serviceCentres: JSON.stringify(serviceCentres),
      };
    };
  }, [serviceCentres]);

  const handleModal = useCallback((show = false, selectedObj) => {
    show = typeof show === "boolean" && show;
    setShowStoreModal(show);
    selectStore(selectedObj);
  }, []);

  const handleStore = useCallback((scObj) => {
    let { index, ...rest } = scObj;

    if (index !== undefined) {
      setServiceCentres((preState) =>
        preState.map((obj, i) => (i === index ? rest : obj))
      );
    } else {
      setServiceCentres((preState) => [...preState, scObj]);
    }
  }, []);

  const handleRemove = useCallback((index) => {
    setServiceCentres((preState) => preState.filter((_, i) => i !== index));
  }, []);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;

      setData((preState) => ({ ...preState, [name]: value }));
    },
    [setData]
  );

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight={"600"}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="form_comp">
            <label htmlFor="title">Title</label>
            <ReactQuill
              placeholder="Enter title"
              value={data?.title}
              onChange={handleChange("title")}
              theme="snow"
              className="edit_container"
              bounds={`[data-text-editor="name"]`}
            />
          </div>
          <div className="form_comp">
            <label htmlFor="title">Description</label>
            <ReactQuill
              placeholder="Enter description"
              value={data?.description}
              onChange={handleChange("description")}
              theme="snow"
              className="edit_container"
              bounds={`[data-text-editor="name"]`}
            />
          </div>
          <div className="btn-container mt15">
            <Button
              variant="contained"
              className="bordered-button float-right"
              onClick={() => handleModal(true)}
            >
              + Add Service Centre
            </Button>
          </div>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 650, px: 2 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: 700 }}
                    align="left"
                    className="tablecell"
                  >
                    Service Centre name
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700 }}
                    align="left"
                    className="tablecell"
                  >
                    Address
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700 }}
                    align="left"
                    className="tablecell"
                  >
                    Rating
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700 }}
                    align="left"
                    className="tablecell"
                  >
                    Edit
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 700 }}
                    align="left"
                    className="tablecell"
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceCentres?.map((serviceCentre, index) => {
                  return (
                    <TableRow
                      key={serviceCentre?.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        <Box className="origin_des">
                          <Box>
                            <Typography
                              style={{
                                maxWidth: 200,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                              className="font600 fs_14"
                            >
                              {serviceCentre?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        <Box className="origin_des">
                          <Box
                            sx={{
                              ml: 3,
                            }}
                          >
                            <Typography
                              style={{
                                maxWidth: 400,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                              className="font600 fs_14"
                              dangerouslySetInnerHTML={{
                                __html: serviceCentre?.address,
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        <Box className="origin_des">
                          <Rating
                            style={{ width: "max-content" }}
                            name="simple-controlled"
                            value={serviceCentre.rating}
                            disabled
                          />
                        </Box>
                      </TableCell>

                      <TableCell
                        align="left"
                        onClick={() =>
                          handleModal(true, {
                            ...serviceCentre,
                            index,
                          })
                        }
                      >
                        <EditIcon />
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleRemove(index)}
                      >
                        <DeleteIcon />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <AddShowRoomModal
        open={showStoreModal}
        handleClose={() => setShowStoreModal(false)}
        selectedStore={selectedStore}
        handleStore={handleStore}
        storeType={storeType}
      />
    </>
  );
};

export default ServiceCentreAccordian;
