import { Box, Button, Modal } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactQuill from "react-quill";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const AddQNAModal = (props) => {
  const { open, handleClose, selectedQNA, handleQNA } = props;

  const prevProp = useRef();

  const [state, setState] = useState({
    question: "",
    answer: "",
  });
  const [error, setError] = useState({});

  useEffect(() => {
    if (!prevProp?.current?.open && open) {
      setState({
        question: selectedQNA?.question ?? "",
        answer: selectedQNA?.answer ?? "",
      });
      setError({});
    }

    return () => {
      prevProp.current = {
        open,
      };
    };
  }, [open, selectedQNA]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const modalTitle = useMemo(
    () => (selectedQNA ? "Edit QNA" : "Add QNA"),
    [selectedQNA]
  );

  const hasError = useCallback(() => {
    let { question, answer } = state;
    question = question?.trim?.();
    answer = answer?.trim?.();

    const error = {};

    if (!question) {
      error.question = "Question cannot be blank";
    }

    if (!answer) {
      error.answer = "Answer cannot be blank";
    }

    setError(error);
    return Object.keys(error).length;
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const payload = {
        ...state,
      };

      if (selectedQNA && selectedQNA.index !== undefined)
        payload.index = selectedQNA.index;
      handleQNA(payload);
      handleClose(false);
    }
  }, [hasError, state, selectedQNA, handleQNA, handleClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>{modalTitle}</h2>
        <div className="form_comp">
          <label htmlFor="title">
            Question
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <ReactQuill
            placeholder="Which electric bike is best in Bangalore?"
            value={state.question}
            onChange={handleChange("question")}
            theme="snow"
            className="edit_container"
            bounds={`[data-text-editor="name"]`}
          />

          {/* <input
            type="text"
            placeholder="Which electric bike is best in Bangalore?"
            value={state.question}
            onChange={handleChange("question")}
            autoComplete="off"
          /> */}
          <span className="error mt5">{error.question}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Answer
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <ReactQuill
            placeholder="Oben electric"
            value={state.answer}
            onChange={handleChange("answer")}
            theme="snow"
            className="edit_container"
            bounds={`[data-text-editor="name"]`}
          />
          {/* <textarea
            rows="2"
            placeholder="Oben electric"
            value={state.answer}
            onChange={handleChange("answer")}
          /> */}
          <span className="error mt5">{error.answer}</span>
        </div>

        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 15,
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            {selectedQNA ? "Update" : "Add"}
          </Button>
          <Button
            variant="text"
            className="bordered-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddQNAModal;
