import React from "react";
import { Navigate } from "react-router-dom";
import {
  adminInfoGetterService,
  userTypefoGetterService
} from "../../shared/services";
import options from "../../features/side-bar/options";

// Helper function to match dynamic routes
const matchRoute = (pattern, path) => {
  console.log("pattern ::",pattern)
  if (!pattern) return false; // Ensure pattern is not undefined
  const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '([\\w-]+)')}$`);
  return regex.test(path);
};

const PrivateRoute = ({ children, slug }) => {
  const token = adminInfoGetterService(); // Get the authentication token
  const userType = userTypefoGetterService(); // Get the user type

  // Find the matching route in the options (both static and dynamic)
  const route = options.find((route) => matchRoute(route.name, slug));

  // If the user has a token and the user type matches the allowed access, render the route
  return token && route && route.access.includes(userType)
    ? children
    : <Navigate to="/" />; // Redirect to login or unauthorized page
};

export default PrivateRoute;
