import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { STATES } from "../../../shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../auth/service";
import { cityActions } from "../duck/CityReducer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const AddCityModal = (props) => {
  const { open, handleClose, selectedCityId } = props;

  const prevProp = useRef();
  const dispatch = useDispatch();
  const selectedCity = useSelector(
    ({ city }) => city?.citiesMap?.[selectedCityId]
  );

  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [cityDetails, setCityDetails] = useState({
    state: "",
    city: "",
    map: "",
    imgAlt: "",
    src: "",
  });
  const [error, setError] = useState({});

  useEffect(() => {
    if (!prevProp?.current?.open && open) {
      setCityDetails({
        state: selectedCity?.state ?? "",
        map: selectedCity?.map ?? "",
        city: selectedCity?.city ?? "",
        imgAlt: selectedCity?.images?.[0]?.imgAlt ?? "",
        src: selectedCity?.images?.[0]?.src ?? "",
      });
      setError({});
    }

    return () => {
      prevProp.current = {
        open,
      };
    };
  }, [open, selectedCity]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setCityDetails((preState) => ({ ...preState, [name]: value }));
      setError({});
    },
    []
  );

  const modalTitle = useMemo(
    () => (selectedCityId ? "Edit City" : "Add City"),
    [selectedCityId]
  );

  const hasError = useCallback(() => {
    let { state, city, map, src } = cityDetails;
    state = state?.trim?.();
    city = city?.trim?.();
    map = map?.trim?.();

    const error = {};
    if (!state) {
      error.state = "Please select state";
    }
    if (!city) {
      error.city = "Please enter city name";
    }
    if (!map) {
      error.map = "Please provide embed link";
    }

    if (!src) {
      error.fileList = "Upload city icon";
    }

    setError(error);
    return Object.keys(error).length;
  }, [cityDetails]);

  const getSelectedFile = useCallback(async (e) => {
    let formData = new FormData();
    try {
      setIsUploadingImg(true);
      const file = e.target.files[0];
      formData.append("image", file);

      const res = await uploadImage(formData);
      if (res.status === "success") {
        const image = res?.data?.Location;
        setCityDetails((prevData) => ({ ...prevData, src: image }));
      }
      setError({});
    } catch (error) {
      setError("Something went wrong");
    } finally {
      setIsUploadingImg(false);
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (!hasError()) {
      const payload = {
        ...cityDetails,
        images: [
          {
            imgAlt: cityDetails?.imgAlt,
            src: cityDetails?.src,
          },
        ],
      };
      if (selectedCityId) {
        payload._id = selectedCityId;
        dispatch(cityActions.onUpdateCity(payload));
      } else {
        dispatch(cityActions.onCreateCity(payload));
      }
      handleClose();
    }
  }, [cityDetails, dispatch, handleClose, hasError, selectedCityId]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>{modalTitle}</h2>
        <div className="form_comp">
          <label htmlFor="title">
            State
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <Select
            style={{ height: 40 }}
            value={cityDetails.state}
            onChange={handleChange("state")}
          >
            {STATES.map((state, i) => (
              <MenuItem key={i} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>

          <span className="error mt5">{error.state}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            City
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            type="text"
            placeholder="Enter city"
            value={cityDetails.city}
            onChange={handleChange("city")}
            autoComplete="off"
          />
          <span className="error mt5">{error.city}</span>
        </div>
        <div className="form_comp">
          <label htmlFor="title">
            Map
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <textarea
            rows="2"
            placeholder="Enter iframe"
            value={cityDetails.map}
            onChange={handleChange("map")}
          />
          <span className="error mt5">{error.map}</span>
        </div>

        <div className="form_comp">
          <label htmlFor="title">
            City Icon <sup style={{ color: "red" }}>*</sup>
          </label>
          {!cityDetails?.src ? (
            <>
              {isUploadingImg ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  className="bordered-button"
                  style={{ width: 200, fontSize: 14, color: "#fff" }}
                  component="label"
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={getSelectedFile}
                    hidden
                  />
                </Button>
              )}
            </>
          ) : (
            <>
              <div style={{ maxWidth: 400 }} className="mUpload-item">
                <img src={cityDetails?.src} width="80px" alt="img" />
                <input
                  className="fw"
                  type="text"
                  placeholder="Enter alt tag"
                  value={cityDetails?.imgAlt}
                  onChange={handleChange("imgAlt")}
                />
                <DeleteIcon
                  onClick={() =>
                    setCityDetails((preState) => ({ ...preState, src: "" }))
                  }
                />
              </div>
            </>
          )}
          <span className="error mt5">{error.fileList}</span>
        </div>

        <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 15,
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            {selectedCity ? "Update" : "Add"}
          </Button>
          <Button
            variant="text"
            className="bordered-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddCityModal;
